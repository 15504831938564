import { Api } from "../../services/api";
import { IUser } from "./types";

export function setUserLocalStorage(user: IUser | null) {
    localStorage.setItem('u', JSON.stringify(user));
}

export function getUserLocalStorage() {
    const json = localStorage.getItem('u')

    if (!json) {
        return null;
    }

    const user = JSON.parse(json)

    return user ?? null;
}

export async function LoginRequest(username: string, password: string) {
    try {
        const request = await Api.get('autenticar', {
            auth: {
                username: username,
                password: password
            }
        })

        return request.data;
    } catch (error: any) {
        return error
    }
}

export async function executeRequestGet(url: string, token: any) {
    const config = {
        headers: {
            'Token': token,
            'Versao-Bitsafira': process.env.REACT_APP_VERSION
        }
    }

    try {
        const response = await Api.get(url, config);
        return response;
    } catch (error: any) {
        return error
    }
}

export async function executeRequestPost(url: string, token: any, data: any) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Token': token,
            'Versao-Bitsafira': process.env.REACT_APP_VERSION
        }
    }

    try {
        const response = await Api.post(url, data, config);
        return response;
    } catch (error: any) {
        return error
    }
}

export async function executeRequestDelete(url: string, token: any, data: any) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Token': token,
            'Versao-Bitsafira': process.env.REACT_APP_VERSION
        },
        data: data
    }

    try {
        const response = await Api.delete(url, config);
        return response;
    } catch (error: any) {
        return error
    }
}