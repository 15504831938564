import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { MODEL_PLANO } from '../../../models';
import { EXCLUIR_PLANO } from '../../../routes_api';
import { formatedPrice, intToAtivoInativo, labelAtivoInativo } from '../../../utils';

export const Plano = () => {
    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'descricao', header: 'Descrição', sortable: true },
        { field: 'valor', header: 'Valor', body: (e) => formatedPrice(e.valor), sortable: true },
        { field: 'ativo', header: 'Situação', sortable: false, body: (e) => labelAtivoInativo(e.ativo), style: { width: '100px' } },
        { field: 'edit', exportable: false, style: { minWidth: '8rem', width: '10rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
            formatedPrice(value.valor).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.descricao).toLowerCase().includes(filter.toLowerCase());
    }

    return (
        <div>
            <BsTelaPadrao
                model={MODEL_PLANO}
                prefixApiDelete={EXCLUIR_PLANO}
                sortcolumn={'nome'}
                sortcolumnorder={1}
                columns={cols}
                onFilterRegisters={onFilter}
            />
        </div>
    );
}
