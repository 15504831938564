import { useRef, useState } from 'react';
import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { EXCLUIR_CONTATO_AVULSO, UPLOAD_CSV } from '../../../routes_api';
import { formatedTelefone, intToAtivoInativo, labelAtivoInativo } from '../../../utils';
import { useAuth } from '../../../contexts/AuthProvider/useAuth';
import { BsInformationModal } from '../../../components/BsInformationModal';
import { BsCircularLoadingModal } from '../../../components/BsCircularLoadingModal';
import { MODEL_CONTATO_AVULSO } from '../../../models';
import { BsOptions } from '../../../components/BsOptions';
import { BsDialog } from '../../../components/BsDialog';
import { Grid } from '@mui/material';
import { BsTextField } from '../../../components/BsBaseField';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { BsConfirmation } from '../../../components/BsConfirmation';
import { toast } from 'react-toastify';

export const ContatoAvulso = () => {
  const auth = useAuth();

  const fileInput = useRef<any>(null);
  const telaPadraoRef = useRef<any>(null);
  const refOptions = useRef<any>(null);
  const refURL = useRef<any>(null);
  const menuRef = useRef<any>(null);
  const confirmationRef = useRef<any>(null);



  const [showInfo, setShowInfo] = useState(false);
  const [messageInfo, setMessageInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [URL, setURL] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const cols: Array<BsColumns> = [
    { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
    { field: 'descricao', header: 'Descrição', sortable: true },
    { field: 'whatsapp', header: 'WhatsApp', sortable: true, body: (e) => formatedTelefone(e.whatsapp) },
    { field: 'ativo', header: 'Situação', sortable: false, body: (e) => labelAtivoInativo(e.ativo), style: { width: '100px' } },
    { field: 'edit', exportable: false, style: { minWidth: '8rem', width: '8rem' } }
  ];

  const onFilter = (value: any, filter: string) => {
    return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.descricao).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
      formatedTelefone(value.whatsapp).toLowerCase().includes(filter.toLowerCase());
  }

  function excluirMultiplosClick() {
    var data = telaPadraoRef.current.getSelecteds();

    if ((!data) || (data.length === 0)) {
      toast.error('Nenhum registro selecionado.');
      return;
    }

    confirmationRef.current.openModal();
  }

  const items: MenuItem[] = [
    {
      style: { padding: 0 },
      items: [
        {
          icon: 'pi pi-upload',
          label: 'CSV',
          command: () => refOptions.current.openModal(),
        },
        {
          icon: 'pi pi-download',
          label: 'Modelo',
          command: () => window.open('https://api.bitsafira.com.br/contatoAvulso/modeloExcel'),
        },
        {
          icon: 'pi pi-trash',
          label: 'Excluir Múltiplos',
          command: () => excluirMultiplosClick()
        }
      ]
    }
  ];

  const functionsGrid: Array<any> = [
    { icon: 'pi pi-bars', label: 'Outros', onClick: ((e: any) => { menuRef.current.toggle(e) }) }
  ]

  const options = [
    { title: 'Importar Arquivo CSV', onClick: () => fileInput.current?.click() },
    { title: 'Importar URL Google Planilhas', onClick: () => refURL.current.openModal() }
  ]

  function closeURL() {
    refURL.current.closeModal();
    setSubmitted(false);
    setURL('');
  }

  function sendPlanilha(json: any) {
    setLoading(true);

    auth.requestPost(UPLOAD_CSV, json).then(
      (response: any) => {
        if (response.data.dados.naoCadastrados.length > 0) {
          let msg = '<strong>' + response.data.mensagem + '<br/><br/>';
          msg = msg + 'Alguns registros não foram cadastrados: </strong><br/>';

          response.data.dados.naoCadastrados.map((naoCadastrados: any) => {
            msg = msg + '<br/>' + naoCadastrados.descricao + ', Nº: ' + naoCadastrados.whatsapp + ', Motivo: ' + naoCadastrados.motivo;
            return msg;
          })

          setLoading(false);
          setMessageInfo(msg);
          setShowInfo(true);
        } else {
          setLoading(false);
          toast.success(response.data.mensagem);
        }

        refOptions.current.closeModal();
        telaPadraoRef.current.refresh();
        closeURL();
      }
    ).catch((error: any) => {
      setLoading(false);
      toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
    });
  };

  function sendURL() {
    setSubmitted(true);

    if (URL)
      sendPlanilha({ urlPlanilhaGoogle: URL })
  }

  async function excluirMultiplos() {
    var data = telaPadraoRef.current.getSelecteds();

    const contadores: any[] = data.map((item: any) => item.contador);

    await auth.requestDelete(EXCLUIR_CONTATO_AVULSO, { lista: contadores }).then((response: any) => {
      toast.success(`Registros excluídos com sucesso.`);
      telaPadraoRef.current.refresh();
    }).catch((error: any) => {
      toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
    })
  }

  return (
    <div>
      <BsCircularLoadingModal open={loading} />
      <BsTelaPadrao
        ref={telaPadraoRef}
        model={MODEL_CONTATO_AVULSO}
        prefixApiDelete={EXCLUIR_CONTATO_AVULSO}
        sortcolumn={'descricao'}
        sortcolumnorder={1}
        columns={cols}
        onFilterRegisters={onFilter}
        adicionalFunctions={functionsGrid}
        columnChecked={true}
      />

      <Menu model={items} popup ref={menuRef} style={{ padding: '2px', width: 'auto', fontSize: '14px' }} />

      <BsConfirmation ref={confirmationRef} confirmClick={() => excluirMultiplos()} message='Deseja mesmo excluir os registros selecionados?' confirmAlert={true} />

      <BsInformationModal open={showInfo} message={messageInfo} close={() => setShowInfo(false)} />

      <BsOptions ref={refOptions} options={options} />

      <BsDialog
        ref={refURL}
        maxWidth={'xs'}
        title={'Informe a URL:'}
        content={
          <Grid container spacing={1.5} sx={{ mt: 0.05 }}>
            <Grid item xs={12}>
              <BsTextField type={'text'} disabled={loading} label={'URL'} value={URL} onChange={(e: any) => setURL(e.target.value)} required submitted={submitted} />
            </Grid>
          </Grid>
        }
        confirmClick={sendURL}
        cancelClick={closeURL}
      />

      <input ref={fileInput} type='file' accept='.csv'
        style={{ height: 0, width: 0, visibility: 'hidden', padding: 0, margin: 0, float: 'right' }}
        onChange={(e: any) => {
          const file = e.target.files[0];

          if (file.type !== "text/csv") {
            toast.error('Selecione apenas arquivos .csv!')
            return;
          }

          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            if (reader.result) {
              const base64 = String(reader.result).split(',')[1];

              sendPlanilha({ csv: base64 });
            }
          }
        }}
      />

    </div >
  );
}
