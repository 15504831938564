import { Collapse, Divider, Grid } from "@mui/material"
import { MobVersion } from "../../../../utils"
import { Inputs } from "../../../Inputs"
import React, { useImperativeHandle, useRef } from "react"
import { BsButtonHeader } from "../BsButtonHeader"

export const Filtro = React.forwardRef((props: any, ref) => {
    const filterEls = useRef<{ [key: string]: any }>({});

    function getFilterEls() {
        return filterEls
    }

    useImperativeHandle(ref, () => ({
        getFilterEls
    }));

    return (
        <Collapse in={props.filter}>
            <Divider style={{ marginTop: '10px' }} />
            <div style={{ display: 'flex', marginTop: '10px' }} >
                <Grid container spacing={2}>
                    {
                        props?.filterInputs?.map((inp: any, index: number) => (
                            <Grid key={index} item xs={MobVersion() ? 12 : 3}>
                                <Inputs
                                    ref={(e: any) => filterEls.current[inp.id] = e}
                                    inp={{ ...inp, size: 'small' }}
                                />
                            </Grid>
                        ))
                    }

                    <Grid item xs={MobVersion() ? 12 : 3}>
                        <BsButtonHeader style={{ marginRight: '5px' }} label='Filtrar' onClick={() => props.applyFilter()} width={MobVersion() ? '100%' : 'auto'} visible={true} ignoraMobVersion={true} height='30px' />
                        <BsButtonHeader style={{ marginRight: '5px', marginTop: '5px' }} label='Limpar Filtro' onClick={() => { props.clearFilter(filterEls) }} width={MobVersion() ? '100%' : 'auto'} visible={true} ignoraMobVersion={true} height='30px' />
                    </Grid>
                </Grid>
            </div>
        </Collapse>
    )
})