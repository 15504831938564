import { Typography } from "@mui/material"
import { BsDialog } from "../../../components/BsDialog"
import { forwardRef, useImperativeHandle, useRef } from "react"
import moment from "moment"

const BsTermoTitle = (props: any) => {
    return (
        <>
            <Typography variant="h6">
                {props.children}
            </Typography>
            <br />
        </>
    )
}

const BsTermoText = (props: any) => {
    return (
        <>
            <Typography variant="body1">{props.children}</Typography>
            <br />
        </>
    )
}

export const TermosUso = forwardRef((props: any, ref) => {
    const dialogRef = useRef<any>();

    function openModal() {
        dialogRef.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal,
    }));

    return (
        <BsDialog
            ref={dialogRef}
            title={'Termos de Uso'}
            labelCancel={'Sair'}
            cancelClick={() => dialogRef.current.closeModal()}
            content={
                <>
                    <BsTermoTitle>{'Bem vindo(a)'}</BsTermoTitle>
                    <BsTermoText>
                        {'Este sistema é fornecido pela empresa BITSAFIRA, e seu uso está sujeito a estas condições de uso.'}
                        {'Ao utilizar a plataforma BitSafira, você concorda com os termos e condições estabelecidos nesta política.'}
                    </BsTermoText>
                    <BsTermoTitle>{'1. Introdução'}</BsTermoTitle>
                    <BsTermoText>{'Este documento apresenta os termos de uso da plataforma BitSafira.'}</BsTermoText>
                    <BsTermoTitle>{'2. Coleta de Dados'}</BsTermoTitle>
                    <BsTermoText>{'A BitSafira coleta informações pessoais do usuário, como nome, whatsapp, usuário e senha, apenas para fins de cadastro e autenticação no sistema.'}</BsTermoText>
                    <BsTermoText>{'Os dados coletados de cliente são usados unicamente para controle e notificação na plataforma. Não realizamos de nenhuma maneira à venda ou utilização para benefício próprio dos mesmos.'}</BsTermoText>
                    <BsTermoTitle>{'3. Armazenamento e Proteção de Dados'}</BsTermoTitle>
                    <BsTermoText>{'A BitSafira se compromete a armazenar os dados dos usuários de forma segura e a tomar todas as medidas necessárias para proteger as informações pessoais coletadas.'}</BsTermoText>
                    <BsTermoText>{'Reconhecemos a importância da privacidade e proteção de dados pessoais. Por isso, a empresa está comprometida em proteger e respeitar a privacidade de seus usuários, em conformidade com a Lei Geral de Proteção de Dados (LGPD).'}</BsTermoText>
                    <BsTermoText>{'Ao utilizar a plataforma BitSafira, você concorda com a coleta, armazenamento e processamento dos seus dados pessoais, conforme estabelecido no Item 2 desse documento.'}</BsTermoText>
                    <BsTermoText>{'É de responsabilidade do usuário fornecer informações precisas e atualizadas.'}</BsTermoText>
                    <BsTermoTitle>{'4. Responsabilidade do Usuário'}</BsTermoTitle>
                    <BsTermoText>{'O usuário é responsável por todas as informações fornecidas a plataforma BitSafira e deve garantir que todas as informações fornecidas sejam precisas e atualizadas.'}</BsTermoText>
                    <BsTermoText>{'A BitSafira é um sistema de gestão de clientes que permite aos usuários gerenciar suas atividades e gestão de negócios. O uso é de responsabilidade do usuário, que deve garantir que o sistema seja usado apenas para fins lícitos e em conformidade com a lei.'}</BsTermoText>
                    <BsTermoText>{'A empresa BITSAFIRA não se responsabiliza por quaisquer danos, perdas ou responsabilidades decorrentes do uso da plataforma por parte do usuário.'}</BsTermoText>
                    <BsTermoTitle>{'5. Propriedade Intelectual'}</BsTermoTitle>
                    <BsTermoText>{'A plataforma BitSafira é de propriedade exclusiva da empresa BITSAFIRA e é protegido pelas leis de direitos autorais e propriedade intelectual. O usuário não tem permissão para copiar, reproduzir, modificar ou distribuir o sistema ou qualquer parte dele sem autorização prévia por escrito da empresa.'}</BsTermoText>
                    <BsTermoTitle>{'6. Disponibilidade do Serviço'}</BsTermoTitle>
                    <BsTermoText>{'A empresa BITSAFIRA se esforça para garantir a disponibilidade contínua da plataforma BitSafira, mas não pode garantir que o sistema estará sempre disponível ou livre de erros. A empresa não se responsabiliza por quaisquer interrupções no serviço ou perda de dados.'}</BsTermoText>
                    <BsTermoTitle>{'7: Bloqueio no WhatsApp'}</BsTermoTitle>
                    <BsTermoText>{'7.1. A Empresa envida todos os esforços razoáveis para evitar bloqueios no WhatsApp, porém, não nos responsabilizamos por quaisquer bloqueios ocorridos durante o uso de nossos serviços.'}</BsTermoText>
                    <BsTermoText>{'7.2. Recomendamos aos usuários que utilizem um número de telefone alternativo, que não seja o número principal, ao utilizar nossos serviços que envolvam o WhatsApp.'}</BsTermoText>
                    <BsTermoText>{'7.3. Os bloqueios no WhatsApp podem ocorrer por uma série de motivos, incluindo ações realizadas pelos próprios usuários, políticas do WhatsApp ou circunstâncias fora de nosso controle. Apesar de adotarmos medidas para evitar bloqueios, não podemos garantir sua total ausência.'}</BsTermoText>
                    <BsTermoText>{'7.4. É responsabilidade do usuário compreender e cumprir as políticas e termos de uso do WhatsApp, bem como as leis e regulamentos aplicáveis ao seu uso. Quaisquer consequências resultantes do bloqueio no WhatsApp, incluindo perda de comunicação ou acesso a determinadas funcionalidades, são de responsabilidade exclusiva do usuário.'}</BsTermoText>
                    <BsTermoText>{'7.5. Caso ocorra um bloqueio no WhatsApp durante o uso de nossos serviços, forneceremos assistência e orientação adequadas na medida do possível. No entanto, não garantimos a solução ou a reversão do bloqueio.'}</BsTermoText>
                    <BsTermoText>{'7.6. Ao concordar com estes termos de uso, o usuário reconhece que o uso do WhatsApp é uma escolha pessoal e que existem riscos associados ao mesmo, incluindo possíveis bloqueios. O usuário assume total responsabilidade por suas decisões de utilizar o WhatsApp e compreende que a Empresa não se responsabiliza por quaisquer consequências decorrentes dessas decisões.'}</BsTermoText>
                    <BsTermoTitle>{'8. Modificações na Política de Termos de Uso'}</BsTermoTitle>
                    <BsTermoText>{'A empresa BITSAFIRA se reserva o direito de modificar esta Política de Termos de Uso a qualquer momento, sem aviso prévio. O usuário deve revisar esta política regularmente para estar ciente de quaisquer alterações.'}</BsTermoText>
                    <BsTermoTitle>{'9. Modificações na Política de Termos de Uso'}</BsTermoTitle>
                    <BsTermoText>{'A plataforma BitSafira se reserva o direito de alterar esta política de termos de uso a qualquer momento. O usuário será notificado por e-mail ou por meio do próprio sistema.'}</BsTermoText>
                    <BsTermoTitle>{'10. Contato'}</BsTermoTitle>
                    <BsTermoText>{'Se você tiver alguma dúvida ou preocupação sobre esta Política de Termos de Uso, entre em contato conosco'}</BsTermoText>
                    <BsTermoText>{'E-mail: bitsafira@gmail.com'}</BsTermoText>
                    <BsTermoText>{'WhatsApp: (34) 99718-6432'}</BsTermoText>
                    <BsTermoTitle>{'11. Conclusão'}</BsTermoTitle>
                    <BsTermoText>{'Ao utilizar a plataforma BitSafira, você concorda com estes termos e condições de uso.'}</BsTermoText>
                    <br />
                    <BsTermoText>{'© ' + moment().year() + ' BITSAFIRA. Todos os direitos reservados.'}</BsTermoText>
                </>
            }
        />
    )
})