import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './contexts/AuthProvider';
import GlobalStyle from './styles/theme';
import ReactGA from "react-ga4";
import { ParamsProvider } from './contexts/ParamsProvider';

const container = document.getElementById('root');
const root = createRoot(container!);
ReactGA.initialize("G-YV6TS9YW1Z");

root.render(
  //<React.StrictMode>
  <AuthProvider>
    <ParamsProvider>
      <BrowserRouter>
        <GlobalStyle />
        <App />
      </BrowserRouter>
    </ParamsProvider>
  </AuthProvider>
  //</React.StrictMode>
);