import { FormControl, TextField, Typography, FormHelperText } from '@mui/material';
import styled from 'styled-components';
import { formatedPrice } from '../../utils';
import { BsDateTimeField } from './DateTimePicker';
import { BsSelectField } from './AutoComplete';

export const FormHelperTextStyled = styled(FormHelperText)`
    width: 100%;
    display: flex;
    justify-content: space-between;
`
export const BsBaseField = (props: any) => {
    return (props.type === 'select' ? BsSelectField(props) : ((props.type === 'datetime-local') || (props.type === 'date')) ? BsDateTimeField(props) : BsTextField(props))
}

export const BsTextField = (props: any) => {
    function Required() {
        return (
            props.submitted && ((props.required && !props.disabled && (props.value === '' || props.value === undefined)) || props.error)
        );
    }

    function GreaterThanZero() {
        return props.submitted && !props.disabled && props.isGreaterThanZero && ((props.value <= 0) || (props.value === undefined))
    }

    function MaxValue() {
        return props.submitted && !props.disabled && props.maxValue && (props.value > props.maxValue)
    }

    return (
        <FormControl fullWidth>
            <TextField
                label={props.label}
                type={props.type}
                value={props.value}
                onChange={(e) => props.onChange(e)}
                required={props.required}
                disabled={props.disabled}
                multiline={props.multiline}
                maxRows={props.maxRows}
                size={props.size ? props.size : 'small'}
                placeholder={props.placeholder ? props.placeholder : props.type === 'number' ? '0,00' : ''}
                autoComplete='off'
                variant={props.variant === "filled" || props.variant === "standard" || props.variant === "outlined" ? props.variant : undefined}
                error={Required() || GreaterThanZero() || MaxValue() || (!props.validate)}
                InputProps={{
                    style: { fontSize: 14 },
                    inputProps: {
                        maxLength: props.maxLength,
                        style: { textAlign: props.textAlign ? props.textAlign : 'left' }
                    },
                }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                autoFocus={props.autoFocus}
                onKeyDown={props.onKeyDown}
            />
            <FormHelperTextStyled sx={{ ml: 0, mt: 0, mb: 0 }} >
                <Typography
                    variant='caption'
                    sx={{ alignSelf: 'flex-start', fontSize: 11 }}
                    color={'error'}
                >
                    {props.submitted && (
                        (Required() && 'Campo obrigatório.') ||
                        (GreaterThanZero() && 'Valor do campo deve ser maior que zero.') ||
                        (MaxValue() && 'Valor do campo deve ser menor ou igual a ' + formatedPrice(props.maxValue)) ||
                        ((!props.validate) && props.messageValidate) ||
                        (props.error && props.errorMsg)
                    )}
                </Typography>

            </FormHelperTextStyled>
        </FormControl >
    );
}
