import React from "react";
import { COLOR_THEME_FONT } from "../../styles/theme";
import { MobVersion } from "../../utils";
import { IconButton, IconButtonProps } from "@mui/material";
import styled from "styled-components";
import MenuIcon from '@mui/icons-material/Menu';

export const ContainerMenu = styled.div`
    position: fixed;
    right: 0;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const WrapperUsuario = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
`

export const BsButtonPopUp: React.FC<IconButtonProps> = (props) => {
    return (
        <IconButton
            size='medium'
            sx={{ backgroundColor: 'rgba(0, 0, 0, .15)', color: COLOR_THEME_FONT, ml: '3px' }}
            {...props}
        />
    )
}

export const BsIconButtonAuxSideBar: React.FC<IconButtonProps> = (props) => {
    return (
        <IconButton
            color="inherit"
            edge="start"
            sx={{ position: `${MobVersion() ? 'fixed' : 'relative'}`, left: `${MobVersion() ? '1rem' : '0'}` }}
            {...props}
        >
            <MenuIcon />
        </IconButton>
    )
}