import { Typography } from "@mui/material"
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { BsDialog } from "../BsDialog";

export interface Props {
    confirmClick: any;
    message: string;
    title?: string;
    confirmAlert?: boolean;
    cancelCaption?: string;
    confirmCaption?: string;
}

export const BsConfirmation = forwardRef((props: Props, ref) => {
    const dialogRef = useRef<any>();

    const [id, setId] = useState();

    function openModal(_id: any) {
        if (_id !== undefined)
            setId(_id);

        dialogRef.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    return (
        <BsDialog
            ref={dialogRef}
            maxWidth={'xs'}
            title={props.title ? props.title : 'Confirmação'}
            content={<Typography> {props.message} </Typography>}
            labelCancel={props.cancelCaption ? props.cancelCaption : 'Não'}
            cancelClick={() => dialogRef.current.closeModal()}
            labelConfirm={props.confirmCaption ? props.confirmCaption : 'Sim'}
            alert={props.confirmAlert}
            confirmClick={
                async () => {
                    try {
                        dialogRef.current.setLoading(true);
                        await props.confirmClick(id);
                    } catch (error: any) {
                        console.log(error);
                        dialogRef.current.setLoading(false);
                    } finally {
                        dialogRef.current.closeModal();
                    }
                }
            }

        />
    )
})