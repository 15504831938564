import { BsTelaPadrao, BsColumns } from '../../components/BsTelaPadrao';
import { CONSULTA_MASTER } from '../../routes_api';
import { formatedDateTime, formatedPrice, formatedTelefone, intToAtivoInativo, labelAtivoInativo } from '../../utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { TransferirCredito } from './TransferirCredito';
import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { MODEL_SUB_REVENDA } from '../../models';
import { GoArrowSwitch } from "react-icons/go";

export const SubRevenda = () => {
    const auth = useAuth();
    const dataTableRef = useRef<any>(null);

    const [credito, setCredito] = useState(0);
    const [open, setOpen] = useState(false);
    const [contador, setContador] = useState(0);

    const getSaldoCredito = useCallback(() => {
        async function fetchCredito() {
            const response: any = await auth.requestGet(CONSULTA_MASTER);
            setCredito(response.data.dados[0].credito)
        }

        fetchCredito();
    }, [auth])

    useEffect(() => {
        getSaldoCredito();
    }, [getSaldoCredito]);

    function transferirClick(rowData: any) {
        setOpen(true);
        setContador(rowData.contador);
    }

    const functionsGrid: Array<any> = [
        { icon: <GoArrowSwitch />, color: '#2563EB', onClick: transferirClick }
    ]

    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'nome', header: 'Nome', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'credito', header: 'Créditos', sortable: true, style: { width: '100px' } },
        { field: 'plano.descricao', header: 'Plano', sortable: true, body: (e: any) => e.plano.descricao + ' - ' + formatedPrice(Number(e.plano.valor)), style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'dataExpiracao', header: 'Expiração', sortable: true, body: (e: any) => formatedDateTime(e.dataExpiracao), style: { width: '125px' } },
        { field: 'email', header: 'E-mail', sortable: true, style: { width: '125px' } },
        { field: 'whatsapp', header: 'WhatsApp', sortable: true, body: (e: any) => formatedTelefone(e.whatsapp), style: { width: '125px' } },
        { field: 'ativo', header: 'Situação', body: (e: any) => labelAtivoInativo(e.ativo), style: { width: '75px' } },
        { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '4rem', width: '4rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
            formatedDateTime(value.dataExpiracao).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            formatedTelefone(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            (value.plano.descricao + ' - ' + formatedPrice(Number(value.plano.valor))).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.nome).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.credito).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.email).toLowerCase().includes(filter.toLowerCase());
    }

    return (
        <div>
            <TransferirCredito
                open={open}
                contador={contador}
                cancelClick={() => setOpen(false)}
                confirmClick={() => {
                    setOpen(false);
                    setContador(0);
                    dataTableRef.current.refresh();
                    getSaldoCredito();
                }}
            />
            <BsTelaPadrao
                ref={dataTableRef}
                model={MODEL_SUB_REVENDA}
                module={'Sub-revendas (Créditos: ' + credito + ')'}
                disabledEdit
                disabledInativar
                sortcolumn={'descricao'}
                sortcolumnorder={1}
                columns={cols}
                onFilterRegisters={onFilter}
            />
        </div>
    );
}
