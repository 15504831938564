import { Card, Grid, useTheme } from '@mui/material';
import { COLOR_THEME } from '../../styles/theme';
import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

const TitleCard = styled.div`
    color: #A3AED0;
    font-size: 14px;
    font-weight: 500;
`
const SubTitleCard = styled.div`
    margin-top: 3px; 
    font-size: 12px;
`
const TextCard = styled.div`
    font-weight: 600;
`

const Container = styled(Card)`
    border-radius: 5px !important;
`
const Wrapper = styled.div`
    display: flex;
    align-items: center;
`
const Image = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; 
`

export const BsCardInfo = forwardRef((props: any, ref: any) => {
    const [value, setValue] = useState('-----');
    const [customColor, setCustomColor] = useState<any>();
    const theme = useTheme();

    useImperativeHandle(ref, () => ({
        setValue,
        setCustomColor
    }));

    return (
        <Container>
            <Grid container sx={{ p: '20px' }}>
                <Grid item xs={3} style={{ alignItems: 'center', display: 'flex' }}>
                    <Image style={{
                        backgroundColor: props.customColor ? props.customColor : customColor ? customColor : theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .10)' : 'rgba(0, 0, 0, .10)',
                        fontSize: '30px',
                        color: (props.customColor || customColor || (theme.palette.mode === 'dark')) ? '#fff' : COLOR_THEME
                    }} >
                        {props.icon}
                    </Image>
                </Grid>
                <Grid item xs={9}>
                    <TitleCard>
                        <Wrapper>
                            {props.title}
                            {props.subtitle !== '' && <SubTitleCard>{props.subtitle}</SubTitleCard>}
                        </Wrapper>
                    </TitleCard>
                    <TextCard style={{ color: theme.palette.mode === 'dark' ? '#ececec' : COLOR_THEME, fontSize: props.fontSize ?? '22px' }}>{props.value ?? value}</TextCard>
                    {props.footer &&
                        <SubTitleCard>{props.footer}</SubTitleCard>
                    }
                </Grid>
            </Grid>
        </Container >
    )
});