import { Menu, MenuItem, MenuProps, Typography, Zoom } from "@mui/material";
import { BsButtonPopUp, ContainerMenu, WrapperUsuario } from "../../../components/BsIconButton";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { Link } from "react-router-dom";
import { BsListItemIcon } from "../../../components/BsListItemIcon";
import { COLOR_THEME, COLOR_THEME_FONT } from "../../../styles/theme";
import { BsListItemText } from "../../../components/BsListItemText";
import React, { useRef, useState } from "react";
import { BsConfirmation } from "../../../components/BsConfirmation";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

interface CustomMenuProps extends MenuProps {
    width: string;
}

const CustomMenu: React.FC<CustomMenuProps> = (props: any) => {
    return (
        <Menu
            PaperProps={{ style: { backgroundColor: COLOR_THEME, marginTop: '3px', borderRadius: '3px', width: props.width } }}
            TransitionComponent={Zoom}
            transitionDuration={100}
            {...props}
        />
    )
}

const MenuItemPopUp = (props: any) => {
    return (
        <MenuItem component={props.toLink ? Link : MenuItem} to={props.toLink} onClick={props.eventClick}>
            <BsListItemIcon sx={{ color: COLOR_THEME_FONT }} icon={props?.icon} />
            <BsListItemText sx={{ color: COLOR_THEME_FONT }} primary={props.label} />
        </MenuItem >
    )
}

export const MenuPerfil = React.forwardRef((props: any, ref: any) => {
    const auth = useAuth();

    const confirmationRef = useRef<any>();

    const [anchorElPerfil, setAnchorElPerfil] = useState<null | HTMLElement>(null);
    const openPerfil = Boolean(anchorElPerfil);

    const menuDadosClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorElPerfil(anchorElPerfil ? null : e.currentTarget)
    };

    return (
        <>
            <ContainerMenu>
                <WrapperUsuario>
                    <Typography variant="button"> {auth.name} </Typography>
                </WrapperUsuario>
                <BsButtonPopUp onClick={menuDadosClick}>
                    <PersonOutlineOutlinedIcon />
                </BsButtonPopUp>
            </ContainerMenu >

            <CustomMenu anchorEl={anchorElPerfil} open={openPerfil} onClose={menuDadosClick} width='175px'>
                <MenuItemPopUp label='Meus Dados' eventClick={() => setAnchorElPerfil(null)} icon={<AdminPanelSettingsOutlinedIcon />} toLink='/meusdados' />
                <MenuItemPopUp label='Sair' eventClick={() => { confirmationRef.current.openModal(); setAnchorElPerfil(null) }} icon={<ExitToAppOutlinedIcon />} />
            </CustomMenu>

            <BsConfirmation ref={confirmationRef} confirmClick={auth.logout} message='Deseja mesmo sair da plataforma?' confirmAlert={true} />
        </>
    )
})