import { Chip, Dialog, DialogActions, DialogContent, Divider, Typography } from "@mui/material";
import { BsLoadingButton } from "../BsLoadingButton";
import { COLOR_THEME, COLOR_THEME_FONT } from "../../styles/theme";

export const BsInformationModal = (props: any) => {
    return (
        <Dialog open={props?.open} onClose={() => props?.close()} fullWidth maxWidth={props.maxWidth ? props.maxWidth : 'sm'} scroll='body'>
            <DialogContent>
                <Divider>
                    <Chip label={props.title ? props.title.toUpperCase() : 'INFORMAÇÃO'} style={{ backgroundColor: COLOR_THEME, color: COLOR_THEME_FONT, fontSize: 12 }} />
                </Divider>
                {props?.children}
                {
                    props.message &&
                    <Typography style={{ whiteSpace: 'pre-line' }} sx={{ mt: 2 }} variant='body2'>
                        <span dangerouslySetInnerHTML={{ __html: props.message }} />
                    </Typography>
                }
            </DialogContent>
            <Divider variant='middle' />
            <DialogActions style={{ justifyContent: 'center' }}>
                <BsLoadingButton loading={false} size={'small'} onClick={() => props?.close()}>{'Fechar'}</BsLoadingButton>
            </DialogActions>
        </Dialog >
    )
}