import { Parametros } from "./Parametros"
import { Usuario } from "./Usuario"
import { Master } from "./Master"
import { useAuth } from "../../contexts/AuthProvider/useAuth"
import React, { useEffect, useRef, useState } from "react"
import { CONSULTA_USUARIO } from "../../routes_api"
import ContainerChart from "../../components/BsPieChart/ContainerChart"
import { Grid } from "@mui/material"
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SettingsIcon from '@mui/icons-material/Settings';
import { Inputs } from "../../components/Inputs"
import { MODEL_SUPORTE_MASTER } from "../../models"
import { BsLoadingButton } from "../../components/BsLoadingButton"
import { MobVersion } from "../../utils"
import * as CryptoJS from 'crypto-js';
import moment from "moment"
import { toast } from "react-toastify"

export const MinhaContaPrincipal = () => {
    const auth = useAuth();

    const refSuporte = useRef<any>();

    const [usuario, setUsuario] = useState<any>();

    useEffect(() => {
        async function fetchUsuario() {
            try {
                const response: any = await auth.requestGet(CONSULTA_USUARIO);
                setUsuario(response.data.dados[0])
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
            }
        }

        fetchUsuario()
    }, [auth]);

    function alterarMaster() {
        if (!refSuporte.current.validate()) {
            toast.error('Necessário informar o Master que deseja receber suporte.');
        } else {
            const master = refSuporte.current.getValue();
            const currentDate = moment().format('DD/MM/YYYY');
            const currentDateMd5 = CryptoJS.MD5(currentDate).toString();

            const newToken =
                master.token.split(currentDateMd5)[9] +
                master.token.split(currentDateMd5)[5] +
                master.token.split(currentDateMd5)[7] +
                master.token.split(currentDateMd5)[6] +
                master.token.split(currentDateMd5)[2] +
                master.token.split(currentDateMd5)[3] +
                master.token.split(currentDateMd5)[4] +
                master.token.split(currentDateMd5)[1] +
                master.token.split(currentDateMd5)[8] +
                master.token.split(currentDateMd5)[0] +
                master.token.split(currentDateMd5)[10];

            auth.alterUser({
                id: master.contador,
                token: newToken,
                name: master.nome,
                diasRestantes: master.diasFaltantes,
                privilegio: master.privilegio
            });

            toast.success('Usuário alterado com sucesso.');
        }
    }

    return (
        <Grid container spacing={2} sx={{ p: '10px' }}>
            {auth.privilegio === 999 &&
                <ContainerChart title={'Suporte'} xs={12}>
                    <div style={{ padding: 10 }}>
                        <Grid container spacing={2} >
                            <Grid item xs={MobVersion() ? 12 : 6}>
                                <Inputs
                                    ref={refSuporte}
                                    register={undefined}
                                    inp={{ id: 'master', label: 'Master', type: 'select', required: true, disableEdit: true, model: MODEL_SUPORTE_MASTER }}
                                />
                            </Grid>
                            <Grid item sx={{ mt: 0.5 }} xs={MobVersion() ? 12 : 2}>
                                <BsLoadingButton fullWidth variant="contained" onClick={(() => alterarMaster())}>Alterar</BsLoadingButton>
                            </Grid>
                        </Grid>
                    </div >
                </ContainerChart >
            }
            <ContainerChart title={'Master'} icon={<AdminPanelSettingsIcon />} xs={12}>
                <Master dadosUsuario={usuario} />
            </ContainerChart>
            <ContainerChart title={'Usuário'} icon={<AssignmentIndIcon />} xs={12}>
                <Usuario />
            </ContainerChart>
            <ContainerChart title={'Parâmetros'} icon={<SettingsIcon />} xs={12}>
                <Parametros />
            </ContainerChart>
        </Grid >
    )
}