import React, { useImperativeHandle } from "react"
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { BsTitle } from "../../../components/BsHeader";
import { colorError, colorWarning } from "../../../styles/theme";

export const LabelVencimento = React.forwardRef((props: any, ref: any) => {
    const auth = useAuth();

    useImperativeHandle(ref, () => ({ refreshMaster }));

    async function refreshMaster() {
        auth.refreshMaster();
    }

    return (
        <>
            {
                auth && (Number(auth.diasRestantes) <= 5) &&
                <BsTitle
                    title={'Acesso a plataforma expira em: ' + auth?.diasRestantes + ' dia(s).'}
                    marginBottom={'1px'}
                    borderBottom={3}
                    backgroundColor={Number(auth?.diasRestantes) < 3 ? colorError : colorWarning}
                />
            }
        </>
    )
});
