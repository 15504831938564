import styled from "styled-components"

export const Div100 = styled.div`
    width: 100%;
`
export const DivCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
`
export const DivSpaceBetween = styled(DivCenter)`
    justify-content: space-between;
    margin-bottom: 10px;
`
export const DivFlexEnd = styled(DivCenter)`
    justify-content: flex-end;
    margin-bottom: 10px;
`
export const Texto = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 5px;
`
export const Titulo = styled(Texto)`
    font-size: 18px;
    font-weight: 500;
`
export const Divider = styled(DivCenter)`
    display: flex;
    height: 2px;
    background-color: #75757586;
    margin-top: 10px;
    margin-bottom: 5px;
`

export const ButtonFilter = styled.button<{ active: boolean; activeColor: string }>`
    border: none;
    padding: 2px 8px;
    border-radius: 6px;
    margin: -4px 4px -4px 3px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    background-color: ${(props) => (props.active ? props.activeColor : '#c0c0c0')};
    color: #fff;
    border: 1px solid ${(props) => (props.active ? props.activeColor : '#c0c0c0')};

    &:hover {
        color: #fff;
        background-color: ${(props) => (props.active ? (props.activeColor) + 'cc' : '#acacac')};
        border: 1px solid ${(props) => (props.active ? props.activeColor : '#acacac')};
    }
`;