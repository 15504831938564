import { useContext, useEffect, useState } from "react"
import { ParamsContext } from "."

export const useParams = () => {
    const context = useContext(ParamsContext);
    const [paramsLoaded, setParamsLoaded] = useState(false);

    useEffect(() => {
        // Executa a função `loadParams` apenas se ainda não tiver sido executada
        if (context && context.loadParams && !paramsLoaded) {
            context.loadParams().then(() => {
                setParamsLoaded(true);
            });
        }
    }, [context, paramsLoaded]);

    return context;
}