import React, { useImperativeHandle, useRef } from "react";
import { BsDialog } from "../BsDialog";
import { BsLoadingButton } from "../BsLoadingButton";
import { Grid } from "@mui/material";

export const BsOptions = React.forwardRef((props: any, ref) => {
    const dialogRef = useRef<any>(null);

    function openModal() {
        dialogRef.current.openModal();
    }

    function closeModal() {
        dialogRef.current.closeModal();
    }

    useImperativeHandle(ref, () => ({
        openModal: openModal,
        closeModal: closeModal
    }));

    return (
        <BsDialog
            ref={dialogRef}
            title={'Selecione a opção desejada:'}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%", maxWidth: "350px"
                    }
                }
            }}
            invisibleActions={true}
            content={
                <Grid container spacing={1.5} sx={{ pl: 1, pr: 1, mt: 0.05 }}>
                    {
                        props?.options.map((option: any, index: number) => (
                            <Grid item xs={12} key={index}>
                                <BsLoadingButton fullWidth onClick={option.onClick}> {option.title} </BsLoadingButton>
                            </Grid>
                        ))
                    }
                    < Grid item xs={12} >
                        <BsLoadingButton fullWidth onClick={closeModal}>Voltar</BsLoadingButton>
                    </Grid >
                </Grid >
            }
        />
    )
});