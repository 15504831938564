import { forwardRef, useImperativeHandle, useRef } from "react";
import { BsDialog } from "../../../../components/BsDialog";
import BsFileUploader from "../../../../components/BsFileUploader";
import { toast } from "react-toastify";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";
import { ANEXAR_IMAGEM_TEMPLATE } from "../../../../routes_api";

export const AnexarImagem = forwardRef((props: any, ref) => {
    const auth = useAuth();

    var contador: any;

    const dialogRef = useRef<any>();
    const fileRef = useRef<any>();

    function openModal(data: any) {
        contador = data.contador;
        dialogRef.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal: openModal
    }));

    async function salvarClick() {
        let request: any = {};
        var doc = fileRef.current.getFileInfo();

        async function submit() {
            await auth.requestPost(ANEXAR_IMAGEM_TEMPLATE, request)
                .then(() => {
                    toast.success('Arquivo anexado com sucesso.');
                    dialogRef.current.closeModal();
                    props.onAfterSave();
                })
                .catch((error: any) => {
                    toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
                });
        }

        if (!doc)
            toast.error('Necessário selecionar uma imagem.')
        else {
            request.contador = contador;
            request.arquivo = {
                tipo: 1,
                formato: doc.extension,
                descricao: doc.name,
                base64: doc.base64
            }

            try {
                dialogRef.current.setLoading(true);
                await submit();
            } finally {
                dialogRef.current.setLoading(false);
            }
        }
    }

    return (
        <BsDialog
            ref={dialogRef}
            title={'Anexar Imagem'}
            maxWidth={'xs'}
            confirmClick={() => salvarClick()}
            cancelClick={() => dialogRef.current.closeModal()}
            content={
                <BsFileUploader ref={fileRef} accept={'image/*'} />
            }
        />
    )
})