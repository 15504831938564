import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { MODEL_LOG_ATIVIDADE } from '../../../models';
import { formatedDateTimeSeconds } from '../../../utils';

export const LogAtividade = () => {
  const cols: Array<BsColumns> = [
    { field: 'data', header: 'Data', body: (e) => formatedDateTimeSeconds(e.data), sortable: true, style: { width: '175px' } },
    { field: 'texto', header: 'Descrição', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'usuario.nome', header: 'Usuário', sortable: true }
  ];

  const onFilter = (value: any, filter: string) => {
    return formatedDateTimeSeconds(value.data).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.usuario.nome).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.texto).toLowerCase().includes(filter.toLowerCase());
  }

  return (
    <div>
      <BsTelaPadrao
        model={MODEL_LOG_ATIVIDADE}
        sortcolumn={'data'}
        sortcolumnorder={-1}
        columns={cols}
        onFilterRegisters={onFilter}
      />
    </div>
  );
}
