import React from 'react';
import { DataTable, DataTableProps } from 'primereact/datatable';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import './styles.css';

export const BsDataTable: React.FC<DataTableProps> = (props) => {
    return (
        <DataTable
            rowHover
            size="small"
            responsiveLayout="stack"
            breakpoint="960px"
            resizableColumns
            emptyMessage="Nenhum registro encontrado."
            rows={props.rows ? props.rows : 50}
            paginatorTemplate={Number(props.value?.length) > (props.rows ? props.rows : 50) ? "RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink" : "CurrentPageReport"}
            currentPageReportTemplate={Number(props.value?.length) > (props.rows ? props.rows : 50) ? "{first} à {last} de {totalRecords}" : "{totalRecords} Registros"}
            expandedRowIcon='pi pi-angle-down'
            collapsedRowIcon='pi pi-angle-right'
            {...props}
        />
    )
}
