import { Modal } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CloseIcon from '@mui/icons-material/Close';
import { COLOR_THEME } from "../../styles/theme";

const Button = styled.button`
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: #fff;
    border: none;
    background-color: rgba(0, 0, 0, .6);
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; 

    &:hover {
        background-color: ${COLOR_THEME};
    }
`

const ModalContent = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Imagem = styled.img<{ zoom: number }>`
    max-height: 80vh;
    max-width: 80vw;
    height: auto;
    width: auto;
    transform: scale(${props => props.zoom});
    transition: transform 0.5s ease;
    z-index: 1;
`;

const ZoomButtonsContainer = styled.div`
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;

const CloseButtonContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
`;

interface BsViewImgProps {
    onAfterClose?: () => void;
}

export const BsViewImg = forwardRef((props: BsViewImgProps, ref: React.Ref<any>) => {
    const [open, setOpen] = useState<boolean>(false);
    const [img64, setImg64] = useState<any>(null);
    const [zoomLevel, setZoomLevel] = useState<number>(1);

    function openModal(_img64: any) {
        setOpen(true);
        setImg64(_img64);
        setZoomLevel(1);
    }

    function closeModal() {
        if (props.onAfterClose)
            props.onAfterClose();

        setOpen(false);
    }

    function handleZoomIn() {
        setZoomLevel(prevZoom => prevZoom + 0.33);
    }

    function handleZoomOut() {
        setZoomLevel(prevZoom => Math.max(0.33, prevZoom - 0.33));
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    return (
        <Modal open={open} onClose={() => closeModal()}>
            <ModalContent>
                <CloseButtonContainer>
                    <Button onClick={closeModal} ><CloseIcon style={{ fontSize: '30px' }} /></Button>
                </CloseButtonContainer>
                <Imagem src={'data:image/png;base64,' + img64} alt="Imagem" zoom={zoomLevel} />
                <ZoomButtonsContainer>
                    <Button onClick={handleZoomIn}><ZoomInIcon style={{ fontSize: '28px' }} /></Button>
                    <Button onClick={handleZoomOut}><ZoomOutIcon style={{ fontSize: '28px' }} /></Button>
                </ZoomButtonsContainer>
            </ModalContent>
        </Modal>
    )
});
