import { FormControl, InputAdornment, OutlinedInput } from "@mui/material"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export const Search = (props: any) => {
    return (
        <FormControl variant='outlined' fullWidth size='small'>
            <OutlinedInput
                placeholder='Buscar'
                value={props?.filter}
                onChange={(e) => props.changeFilter && props.changeFilter(e.target.value)}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchOutlinedIcon />
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}