import { useRef } from 'react';
import { BsColumns, BsTelaPadrao } from '../../../components/BsTelaPadrao';
import { formatedTelefone } from '../../../utils';
import { AdicionarCredito } from './Adicionar Crédito';
import { EXCLUIR_REVENDA } from '../../../routes_api';
import { MODEL_REVENDA_ADM } from '../../../models';
import { RiFunctionAddFill } from "react-icons/ri";

export const Revendas = () => {
    const dataTableRef = useRef<any>(null);
    const adicionaCreditoRef = useRef<any>(null);

    const adicionarCreditosClick = (rowData: any) => {
        adicionaCreditoRef.current.openModal(rowData);
    }

    const functionsGrid: Array<any> = [
        { icon: <RiFunctionAddFill />, color: '#3B82F6', onClick: adicionarCreditosClick },
    ]

    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'nome', header: 'Descrição', sortable: true },
        { field: 'credito', header: 'Crédito', sortable: true },
        { field: 'whatsapp', header: 'WhatsApp', sortable: true, body: (e) => formatedTelefone(e.whatsapp), style: { width: '125px' } },
        { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '6rem', width: '6rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return String(value.credito).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            formatedTelefone(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.nome).toLowerCase().includes(filter.toLowerCase());
    }

    return (
        <div>
            <BsTelaPadrao
                ref={dataTableRef}
                model={MODEL_REVENDA_ADM}
                prefixApiDelete={EXCLUIR_REVENDA}
                sortcolumn={'nome'}
                sortcolumnorder={1}
                columns={cols}
                onFilterRegisters={onFilter}
                disabledEdit
            />
            <AdicionarCredito
                ref={adicionaCreditoRef}
                onFinish={() => dataTableRef.current.refresh()}
            />
        </div>
    );
}
