import { BsColumns, BsTelaPadrao } from "../../../components/BsTelaPadrao";
import { formatedDateTime, formatedPrice, intToCreditoSolicitado, intToTipoCredito, labelCreditoSolicitado, labelTipoCredito } from "../../../utils";
import { useState } from "react";
import { BsInformationModal } from "../../../components/BsInformationModal";
import { Box } from "@mui/material";
import { toast } from 'react-toastify';
import copy from "copy-to-clipboard";
import { MODEL_CREDITO_SOLICITADO } from "../../../models";
import { IoQrCode } from "react-icons/io5";
import { GoCopy } from "react-icons/go";

export const SolicitarCredito = () => {
    const [showInformation, setShowInformation] = useState(false);
    const [childrenQrCode, setChildrenQrCode] = useState<any>();
    const [titleInformation, setTitleInformation] = useState('');

    function showQrCode(rowData: any) {
        setTitleInformation('Pix: ' + rowData.pix.descricao);
        setChildrenQrCode(
            <Box style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                <img style={{ maxHeight: '250px' }} alt='' src={'data:image/png;base64,' + rowData.pix.qrCode} />
            </Box>
        );

        setShowInformation(true);
    }

    const functionsGrid: Array<any> = [
        { icon: <IoQrCode />, color: '#14B8A6', onClick: showQrCode },
        {
            icon: <GoCopy />, color: '#2563EB', onClick: (rowData: any) => {
                copy(rowData.pix.copiaCola);
                toast.success('Pix copiado com sucesso.');
            }
        }
    ]

    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'pix.descricao', header: 'Descrição Pix', sortable: true },
        { field: 'data', header: 'Data Solicitação', body: (e: any) => formatedDateTime(e.data), sortable: true, style: { width: '150px' } },
        { field: 'quantidade', header: 'Quantidade', sortable: true, style: { width: '100px' } },
        { field: 'pix.valor', header: 'Total', sortable: true, body: (e) => formatedPrice(e.pix.valor), style: { width: '100px' } },
        { field: 'tipo', header: 'Tipo', sortable: true, body: (e) => labelTipoCredito(e.tipo), style: { width: '100px' } },
        { field: 'situacao', header: 'Situação', body: (e) => labelCreditoSolicitado(e.situacao), style: { width: '125px' } },
        { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '4rem', width: '4rem' } }
    ];

    const colsExpanded: Array<BsColumns> = [
        { field: 'pix.data', header: 'Emissão Pix', body: (e: any) => formatedDateTime(e.pix.data), style: { width: '150px' } },
        { field: 'pix.dataRecebimento', header: 'Recebimento Pix', body: (e: any) => formatedDateTime(e.pix.dataRecebimento), style: { width: '150px' } },
        { field: 'pix.linkPagamento', header: 'Link Pagamento' },
        { field: 'pix.taxa', header: 'Taxa', body: (e) => formatedPrice(e.pix.taxa), style: { width: '100px' } },
        { field: 'pix.valorLiquido', header: 'Total Líquido', body: (e) => formatedPrice(e.pix.valorLiquido), style: { width: '100px' } },
    ]

    const onFilter = (value: any, filter: string) => {
        return String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.pix.descricao).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.quantidade).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.pix.linkPagamento).toLowerCase().includes(filter.toLowerCase()) ||
            formatedDateTime(value.data).toLowerCase().includes(filter.toLowerCase()) ||
            formatedDateTime(value.pix.data).toLowerCase().includes(filter.toLowerCase()) ||
            formatedDateTime(value.pix.dataRecebimento).toLowerCase().includes(filter.toLowerCase()) ||
            formatedPrice(value.pix.valorLiquido).toLowerCase().includes(filter.toLowerCase()) ||
            formatedPrice(value.pix.taxa).toLowerCase().includes(filter.toLowerCase()) ||
            formatedPrice(value.pix.valor).toLowerCase().includes(filter.toLowerCase()) ||
            intToCreditoSolicitado(value.situacao).toLowerCase().includes(filter.toLowerCase()) ||
            intToTipoCredito(value.tipo).toLowerCase().includes(filter.toLowerCase());
    }

    return (
        <>
            <BsInformationModal
                open={showInformation}
                title={titleInformation}
                maxWidth={'xs'}
                children={childrenQrCode}
                close={() => setShowInformation(false)}
            />

            <BsTelaPadrao
                model={MODEL_CREDITO_SOLICITADO}
                sortcolumn={'descricao'}
                sortcolumnorder={1}
                columns={cols}
                columnsExpanded={colsExpanded}
                onFilterRegisters={onFilter}
                disabledEdit
            />
        </>
    )
}