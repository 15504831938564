import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { labelAtivoInativo, formatedPrice, intToAtivoInativo } from '../../../utils';
import { EXCLUIR_SERVICO } from '../../../routes_api';
import { MODEL_SERVICO } from '../../../models';

export const Servico = () => {
  const cols: Array<BsColumns> = [
    { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
    { field: 'descricao', header: 'Descrição', sortable: true },
    { field: 'tipoServico.descricao', header: 'Tipo Serviço', sortable: true },
    { field: 'custo', header: 'Custo', body: (e) => formatedPrice(e.custo), sortable: true },
    { field: 'valor', header: 'Valor', body: (e) => formatedPrice(e.valor), sortable: true },
    { field: 'ativo', header: 'Situação', sortable: false, body: (e) => labelAtivoInativo(e.ativo), style: { width: '100px' } },
    { field: 'edit', exportable: false, style: { minWidth: '8rem', width: '10rem' } }
  ];

  const onFilter = (value: any, filter: string) => {
    return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
      formatedPrice(value.valor).toLowerCase().includes(filter.toLowerCase()) ||
      formatedPrice(value.custo).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.descricao).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.tipoServico.descricao).toLowerCase().includes(filter.toLowerCase());
  }

  return (
    <div>
      <BsTelaPadrao
        prefixApiDelete={EXCLUIR_SERVICO}
        sortcolumn={'descricao'}
        sortcolumnorder={1}
        columns={cols}
        model={MODEL_SERVICO}
        onFilterRegisters={onFilter}
      />
    </div>
  );
}
