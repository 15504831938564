import { Modal } from "@mui/material";
import { BsCircularLoading } from "../BsCircularLoading";

export const BsCircularLoadingModal = (props: any) => {
    return (
        <Modal open={props.open} style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
            <div>
                <BsCircularLoading open={props.open} title={props.title} />
            </div>
        </Modal>
    )
}