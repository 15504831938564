import { forwardRef, useImperativeHandle, useState } from "react";
import { BsInformationModal } from "../../../components/BsInformationModal";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

export const ViewNotificacao = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const [open, setOpen] = useState<Boolean>(false);

    function openModal() {
        setOpen(true)
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    async function visualiza() {
        await auth.requestPost('/notificacoes/visualizar', { "contador": props.notificacao?.contador });
    }

    if ((open) && (props.notificacao?.visualizado === 0))
        visualiza()

    function onAfterView() {
        setOpen(false);

        if (props.onAfterView)
            props.onAfterView();
    }

    return (
        <BsInformationModal
            open={open}
            title={props.notificacao?.titulo}
            maxWidth={'xs'}
            message={props.notificacao?.conteudo}
            close={onAfterView}
        />
    )
});
