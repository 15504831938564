import React, { useContext, useImperativeHandle, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { toast } from 'react-toastify';
import { BsDialog } from "../../../../components/BsDialog";
import { Inputs } from "../../../../components/Inputs";
import { ENVIAR_MSG_TESTE } from "../../../../routes_api";

export const MensagemTeste = React.forwardRef((props: any, ref) => {
    const auth = useContext(AuthContext);

    const dialogRef = useRef<any>();
    const whatsAppRef = useRef<any>();
    const msgRef = useRef<any>();

    const [id, setId] = useState<any>();
    const [submitted, setSubmitted] = useState(false);

    function openModal(_id: any) {
        setSubmitted(false);
        setId(_id);

        dialogRef.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    function setLoading(value: boolean) {
        dialogRef.current.setLoading(value);
        whatsAppRef.current.setLoading(value);
        msgRef.current.setLoading(value);
    }

    const sendMsgTeste = () => {
        async function sendMsg() {
            setLoading(true);

            const request: any = {
                numero: whatsAppRef.current.getValue(),
                mensagem: msgRef.current.getValue(),
                envioImediato: 0,
                id: id
            };

            try {
                const response: any = await auth.requestPost(ENVIAR_MSG_TESTE, request);

                toast.success(response.data.mensagem);

                dialogRef.current.closeModal();
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.')
            }

            setLoading(false);
        }

        setSubmitted(true);

        if (whatsAppRef.current.validate() && msgRef.current.validate())
            sendMsg();
    };

    return (
        <BsDialog
            ref={dialogRef}
            title={'Enviar Mensagem de Teste'}
            maxWidth={'xs'}
            labelConfirm={'Enviar'}
            cancelClick={() => dialogRef.current.closeModal()}
            confirmClick={() => sendMsgTeste()}
            content={
                <Grid container spacing={2} sx={{ mt: 0.5 }} >
                    <Grid item xs={12}>
                        <Inputs
                            ref={whatsAppRef}
                            inp={{ id: 'whatsapp', type: 'text', label: 'WhatsApp', maxLength: 20, required: true }}
                            submitted={submitted}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Inputs
                            ref={msgRef}
                            inp={{ id: 'msg', type: 'text', label: 'Mensagem', required: true, multiline: true, maxRows: 4, maxLength: 500 }}
                            submitted={submitted}
                        />
                    </Grid>
                </Grid>
            }
        />
    )
})