import React, { useImperativeHandle, useRef, useState } from "react";
import { BsDialog } from "../../../components/BsDialog";
import { BsTextField } from "../../../components/BsBaseField";
import { Grid } from "@mui/material";
import { toast } from 'react-toastify';

import { TRANSFERIR_CREDITO } from "../../../routes_api";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

export const TransferirCredito = React.forwardRef((props: any, ref) => {
    const auth = useAuth();
    

    const dialogRef = useRef<any>();

    const [quantidade, setQuantidade] = useState(1);

    function setLoading(value: boolean) {
        dialogRef.current.setLoading(value);
    }

    function openModal() {
        dialogRef.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal,
    }));

    async function transferirClick() {
        async function transferirCredito() {
            setLoading(true);

            let request: any = {};

            request.contador = Number(props.contador);
            request.quantidade = Number(quantidade);

            try {
                const response: any = await auth.requestPost(TRANSFERIR_CREDITO, request);
                toast.success(response.data.mensagem);
                props.confirmClick(true);
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
            }

            setLoading(false);
        }

        if (quantidade > 0)
            transferirCredito();
    }

    return (
        <BsDialog
            ref={dialogRef}
            title='Tranferir Crédito'
            cancelClick={props.cancelClick}
            confirmClick={transferirClick}
            content={
                <Grid container spacing={2} sx={{ mt: 0.5 }} >
                    <Grid item xs={12}>
                        <BsTextField type='number' label='Quantidade' value={quantidade} onChange={(e: any) => setQuantidade(e.target.value)} submitted isGreaterThanZero />
                    </Grid>
                </Grid>
            }
        />
    )
})