
import { ListItemText, ListItemTextProps } from "@mui/material";
import { COLOR_THEME_FONT } from "../../styles/theme";

interface BsListItemTextProps extends ListItemTextProps {
    label?: string;
    fontWeight?: string;
}

export const BsListItemText = (props: BsListItemTextProps) => {
    return (
        <ListItemText
            primaryTypographyProps={{
                color: COLOR_THEME_FONT,
                fontWeight: props.fontWeight ? props.fontWeight : 'medium',
                fontSize: '0.85rem',
                variant: 'body2',
            }}
            sx={{ my: 0.35, whiteSpace: 'pre-line' }}
            primary={props.label}
            {...props}
        />
    )
}