
import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { MobVersion } from '../utils';

interface IState {
    options?: any;
    series?: any;
    height?: any;
}

class BarChart extends React.Component<any, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            series: [],
            options: {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: !MobVersion()
                    },
                    zoom: {
                        enabled: !MobVersion()
                    }
                },
                colors: ['#03A9F4'],
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '75%',
                        endingShape: 'rounded',
                        borderRadiusApplication: 'end',
                        borderRadius: 5,
                    },
                },
                labels: [],
                fill: {
                    opacity: 1
                }
            }
        }
    }

    setSeries = (series: any) => {
        this.setState({
            series
        });
    }

    setOptions = (labels: any[] | [], maxValue: any | null) => {
        this.setState({
            options: {
                ...this.state.options,
                labels,
                yaxis: {
                    ...this.state.options.yaxis,
                    max: maxValue ? maxValue : null
                },
                dataLabels: {
                    enabled: true
                }
            }
        });
    }

    setTheme = (theme: string) => {
        this.setState({
            options: {
                ...this.state.options,
                theme: {
                    mode: theme,
                    palette: 'palette2'
                },
                chart: {
                    type: 'line',
                    background: (theme === 'dark') ? '#1E1E1E' : 'white'
                },
            }
        });
    }

    render() {
        return (
            <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                width={'95%'}
                height={'350px'}
                type='bar'
                align="center"
            />
        )
    }
}

export default BarChart;