import React, { useImperativeHandle, useRef, useState } from "react";
import { Filtro } from "./Filtro";
import { Search } from "./Search";
import { BsButtonHeader } from "./BsButtonHeader";
import { FilterButton } from "../../../new_components/FilterButton";

export const HeaderTelaPadrao = React.forwardRef((props: any, ref) => {
    const [filter, setFilter] = useState(false);

    const filtroRef = useRef<any>(null);
    const headerRef = useRef<any>(null);

    function getRefsFilter() {
        return filtroRef.current.getFilterEls()
    }

    useImperativeHandle(ref, () => ({
        getRefsFilter
    }));

    return (
        <div ref={headerRef} style={{ marginTop: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Search
                    filter={props.filter}
                    changeFilter={props.changeFilter}
                />
                <div style={{ display: 'flex' }}>
                    <BsButtonHeader icon={filter ? "pi pi-filter-fill" : "pi pi-filter"} label='Filtro' onClick={() => { setFilter(!filter) }} width={100} visible={props?.filterInputs} style={{ marginLeft: '5px' }} />
                    <BsButtonHeader icon="pi pi-file-export" label='Relatório' onClick={() => props.exportToExcel()} width={100} visible={true} style={{ marginLeft: '5px' }} />
                    <BsButtonHeader icon="pi pi-plus" label='Novo' onClick={() => props.novoClick && props.novoClick()} width={100} visible={props.novoVisible} style={{ marginLeft: '5px' }} />
                    <BsButtonHeader icon="pi pi-refresh" label='Recarregar' width={125} visible onClick={() => { props.refreshClick && props.refreshClick() }} style={{ marginLeft: '5px' }} />

                    {
                        props.adicionalFunctions?.map((button: any, i: number) => (
                            <BsButtonHeader key={i} icon={button.icon} label={button.label} onClick={(e: any) => button.onClick(e)} children={button.children} visible style={{ marginLeft: '5px' }} aria-haspopup />
                        ))
                    }
                </div>
            </div>

            <div style={{ display: 'flex' }}>
                {
                    (props.register) && (props.register.ativo !== undefined) &&
                    <div style={{ padding: '10px 0px 0px' }}>
                        <FilterButton
                            active={props.showInativos}
                            label="Mostrar Inativos"
                            onClick={() => props.changeSwitchInativo && props.changeSwitchInativo()}
                            color='#f16d00'
                        />
                        <FilterButton
                            active={props.showAtivos}
                            label="Mostrar Ativos"
                            onClick={() => props.changeSwitchAtivo && props.changeSwitchAtivo()}
                            color='#468844'
                        />
                    </div>
                }

                {props.customHeader && props.customHeader()}
            </div>

            <Filtro
                ref={filtroRef}
                filter={filter}
                filterInputs={props.filterInputs}
                applyFilter={props.applyFilter}
                clearFilter={props.clearFilter}
            />
        </div >
    )
})