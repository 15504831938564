import { IconButton } from "@mui/material";
import styled from "styled-components";

export const PanelLogo = styled.div`
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    min-height: 75px;
    max-height: 150px;
    border: 1px solid rgba(255, 255, 255, .2);
    border-radius: 8px;
`

export const InternalPanel = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`

export const ButtonEditLogo = styled(IconButton)`
    top: 5px;
    right: 5px;
`

export const Logo = styled.img`
    max-width: 80%;
    max-height: 80%;
`