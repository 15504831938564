import { Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Api } from "../../../services/api";
import { toast } from "react-toastify";
import { DadosCliente, DadosEmpresa, DadosPagamento, DivCenter, PagamentoConfirmado } from "./components";
import styled from "styled-components";
import { MobVersion } from "../../../utils";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { PaginaIndisponivel } from "../../Outras/Indisponivel";

const Container = styled(DivCenter)`
    height: ${MobVersion() ? `auto` : `100vh`};
    min-width: 100%;
    background-color: #233e59;
    font-family: 'ubuntu';
`

const Wrapper = styled(Card)`
    display: flex;
    width: 800px;
    min-height: ${MobVersion() ? `1200px` : `600px`};
`

export const Pagamento = (props: any) => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", title: 'BitSafira - Pagamentos' });
    }, [location]);

    const params: any = new URLSearchParams(window.location.search);

    const [data, setData] = useState<any>();
    const [dadosPagamento, setDadosPagamento] = useState<any>();
    const [pago, setPago] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Api.get('/clienteServico?token=' + params.get('token'))
            .then((response: any) => {
                setData(response.data.dados);
            })
            .catch((error: any) => {
                toast.error(error);
                console.log(error)
            }).finally(() => {
                setLoading(true);
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const checkPixStatus = async () => {
            if (!pago) {
                await Api.get('/clienteServico/consultarPagamento?token=' + params.get('token') + '&transactionId=' + dadosPagamento.transactionId)
                    .then((response: any) => {
                        if (response.data.dados.status === 'pago')
                            setPago(true)
                    })
                    .catch((error: any) => {
                        toast.error(error?.response?.data?.mensagem || 'Não foi possível concluir a operação');
                        console.log(error);
                    });
            }
        };

        if (dadosPagamento && dadosPagamento.transactionId && !pago) {
            const intervalId = setInterval(checkPixStatus, 5000);
            return () => clearInterval(intervalId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosPagamento, pago]);

    return (
        <>
            {loading &&
                <>
                    {data.cliente ?
                        < Container >
                            <Wrapper>
                                <Grid container spacing={2}>
                                    <Grid item xs={MobVersion() ? 12 : 6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#c2d4f6' }} >
                                        <DadosEmpresa
                                            logo={data.master.logo}
                                            empresa={data.master.nome}
                                            servico={data.servico.descricao}
                                            valor={data.valor}
                                        />
                                    </Grid>
                                    <Grid item xs={MobVersion() ? 12 : 6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f9f9f9' }}>
                                        {pago ?
                                            <PagamentoConfirmado />
                                            : dadosPagamento ?
                                                <DadosPagamento
                                                    qrCode={'data:image/png;base64,' + dadosPagamento.qrCode}
                                                    copiaCola={dadosPagamento.copiaCola}
                                                    dataValidade={dadosPagamento.dataValidade}
                                                /> :
                                                <DadosCliente
                                                    nome={data.cliente.nome}
                                                    servico={data.servico.descricao}
                                                    dataExpiracao={data.dataExpiracao}
                                                    setDadosPagamento={(_dadosPagamento: any) => setDadosPagamento(_dadosPagamento)}
                                                />
                                        }
                                    </Grid>
                                </Grid>
                            </Wrapper>
                        </Container > :
                        <PaginaIndisponivel description="URL de Pagamento Inválida"/>
                    }
                </>
            }
        </>
    )
}