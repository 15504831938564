import { useRef } from "react";
import { BsColumns, BsTelaPadrao } from "../../../components/BsTelaPadrao";
import { EXCLUIR_LISTA_TRANSMISSAO, NOTIFICAR_LISTA_TRANSMISSAO } from "../../../routes_api";
import { intToAtivoInativo, intToConsiderarAtivos, intToConsiderarVencidos, intToTipoLista, labelAtivoInativo } from "../../../utils";
import { Notificar } from "../../Comuns/Notificar";
import { COLOR_THEME } from "../../../styles/theme";
import { BsButtonDataTable } from "../../../components/BsButtonDataTable";
import copy from "copy-to-clipboard";
import { toast } from 'react-toastify';
import { FaWhatsapp } from "react-icons/fa";

import { MODEL_LISTA_TRANSMISSAO } from "../../../models";

export const ListaTransmissao = () => {
  const notificaRef = useRef<any>(null);

  const notificarClick = (rowData: any) => {
    notificaRef.current.openModal(rowData.contador, NOTIFICAR_LISTA_TRANSMISSAO, rowData.descricao, 'Lista Transmissão', 6);
  }

  const functionsGrid: Array<any> = [
    { icon: <FaWhatsapp />, color: '#0FC144', onClick: notificarClick }
  ]

  const cols: Array<BsColumns> = [
    { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
    { field: 'descricao', header: 'Descrição', sortable: true },
    { field: 'ativos', header: 'Considera Situação', body: (e) => intToConsiderarAtivos(e.ativos), style: { width: '75px' } },
    { field: 'vencidos', header: 'Considera Expiração', body: (e) => intToConsiderarVencidos(e.vencidos), style: { width: '75px' } },
    { field: 'tipo', header: 'Tipo', body: (e) => intToTipoLista(e.tipo), style: { width: '75px' } },
    { field: 'ativo', header: 'Situação', sortable: false, body: (e) => labelAtivoInativo(e.ativo), style: { width: '100px' } },
    { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { width: '5rem' } }
  ];

  const colsExpanded: Array<BsColumns> = [
    {
      field: 'link', header: 'URL', body: (e) =>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a href={e.link} target="_blank" rel="noreferrer" style={{ color: COLOR_THEME }}> {e.link} </a>
          <BsButtonDataTable visible={e.link !== ''} icon='pi pi-copy' className='p-button-info'
            onClick={() => {
              copy(e.link);
              toast.success('URL copiada com sucesso.');
            }}
          />
        </div>
      , style: { width: window.innerWidth, whiteSpace: 'pre-wrap' }
    }
  ]

  const onFilter = (value: any, filter: string) => {
    return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.descricao).toLowerCase().includes(filter.toLowerCase()) ||
      intToConsiderarAtivos(value.ativos).toLowerCase().includes(filter.toLowerCase()) ||
      intToConsiderarVencidos(value.vencidos).toLowerCase().includes(filter.toLowerCase()) ||
      intToTipoLista(value.tipo).toLowerCase().includes(filter.toLowerCase());
  }

  return (
    <>
      <BsTelaPadrao
        model={MODEL_LISTA_TRANSMISSAO}
        prefixApiDelete={EXCLUIR_LISTA_TRANSMISSAO}
        columns={cols}
        columnsExpanded={colsExpanded}
        sortcolumn={'descricao'}
        sortcolumnorder={1}
        onFilterRegisters={onFilter}
      />

      <Notificar ref={notificaRef} />
    </>
  )
}