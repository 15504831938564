import React, { useContext, useImperativeHandle, useRef, useState } from "react";
import { RENOVAR_MASTER } from "../../../../routes_api";
import { Grid } from "@mui/material";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { toast } from 'react-toastify';
import { BsDialog } from "../../../../components/BsDialog";
import { Inputs } from "../../../../components/Inputs";

interface Props {
    onFinish: any;
}

export const RenovarMaster = React.forwardRef((props: Props, ref) => {
    const auth = useContext(AuthContext);
    
    const [register, setRegister] = useState<any>();

    const dialogRef = useRef<any>();
    const itemEls = useRef<{ [key: string]: any }>({});

    function setLoading(value: boolean) {
        dialogRef.current.setLoading(value);

        if (itemEls)
            Object.values(itemEls.current).forEach((elementRef: any) => { elementRef.setLoading(value) });
    }

    function openModal(data: any) {
        setRegister(data);
        dialogRef.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal,
    }));

    const renovarMaster = () => {
        async function renova() {
            setLoading(true);

            let request = {
                contador: register.contador,
                quantidadeMeses: itemEls.current['quantidade'].getValue()
            };

            try {
                const response: any = await auth.requestPost(RENOVAR_MASTER, request);

                toast.success(response.data.mensagem);

                dialogRef.current.closeModal();

                props.onFinish(true);
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
                setLoading(false);
            }
        }

        if (itemEls.current['quantidade'].validate())
            renova();
    }

    const inputs = [
        { id: 'nome', label: 'Master', type: 'text', disabled: true, xs: 12, multiline: true, maxRows: 4 },
        { id: 'dataExpiracao', label: 'Data de Expiração Atual', disabled: true, xs: 12, type: 'datetime-local' },
        { id: 'quantidade', label: 'Quantidade', xs: 12, type: 'number', required: true, isGreaterThanZero: true, default: 1 }
    ]

    return (
        <BsDialog
            ref={dialogRef}
            maxWidth={'xs'}
            title={'Renovar Master'}
            confirmClick={renovarMaster}
            cancelClick={() => dialogRef.current.closeModal()}
            content={
                <Grid container spacing={2} sx={{ mt: 0.5 }} >
                    {
                        inputs.map((input: any, i: number) => (
                            <Grid key={i} item xs={12}>
                                <Inputs
                                    ref={(e: any) => itemEls.current[input.id] = e}
                                    inp={input}
                                    submitted={true}
                                    register={register}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            }
        />
    )
})