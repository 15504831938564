import { BsColumns, BsTelaPadrao } from "../../../components/BsTelaPadrao";
import { MODEL_MOV_FINANCEIRO } from "../../../models";
import { EXCLUIR_MOV_FINANCEIRO } from "../../../routes_api";
import { formatedDate, formatedPrice, labelTipoMovimento } from "../../../utils";

export const MovimentoFinanceiro = () => {
    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'data', header: 'Data', sortable: true, body: (e: any) => formatedDate(e.data), style: { width: '125px' } },
        { field: 'valor', header: 'Valor', sortable: true, body: (e: any) => formatedPrice(e.valor), style: { width: '100px' } },
        { field: 'historico', header: 'Histórico', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'tipo', header: 'Operação', sortable: true, body: (e: any) => labelTipoMovimento(e.tipo), style: { width: '100px' } },
        { field: 'edit', exportable: false, style: { minWidth: '3rem', width: '3rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return true;
    }

    return (
        <BsTelaPadrao
            sortcolumn={'contador'}
            sortcolumnorder={1}
            columns={cols}
            model={MODEL_MOV_FINANCEIRO}
            onFilterRegisters={onFilter}
            disabledEdit={true}
            prefixApiDelete={EXCLUIR_MOV_FINANCEIRO}
        />
    );

}