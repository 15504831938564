import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { AuthContext } from '../../../contexts/AuthProvider';
import { NOTIFICAR_MASTER, CONSULTA_MASTER } from '../../../routes_api';
import { formatedDateTime, labelAtivoInativo, formatedPrice, formatedTelefone, intToAtivoInativo } from '../../../utils';
import { Notificar } from '../../Comuns/Notificar';
import { RenovarMaster } from './Renovar';
import { MODEL_REVENDA_MASTER } from '../../../models';
import { MdAutorenew } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";

export const Master = () => {
    const dataTableRef = useRef<any>(null);
    const renovaRef = useRef<any>(null);
    const notificaRef = useRef<any>(null);

    const auth = useContext(AuthContext);

    const [credito, setCredito] = useState(0);

    const getSaldoCredito = useCallback(() => {
        async function fetchCredito() {
            const response: any = await auth.requestGet(CONSULTA_MASTER);
            setCredito(response.data.dados[0].credito)
        }

        fetchCredito();
    }, [auth])

    useEffect(() => {
        getSaldoCredito();
    }, [auth, getSaldoCredito]);

    const renovarClick = (rowData: any) => {
        renovaRef.current.openModal(rowData);
    }

    const notificarClick = (rowData: any) => {
        notificaRef.current.openModal(rowData.contador, NOTIFICAR_MASTER, rowData.nome, 'Master', 2);
    }

    const functionsGrid: Array<any> = [
        { icon: <MdAutorenew />, color: '#2563EB', onClick: renovarClick },
        { icon: <FaWhatsapp />, color: '#10C245', onClick: notificarClick }
    ]

    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'nome', header: 'Nome', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'plano.descricao', header: 'Plano', sortable: true, body: (e: any) => e.plano.descricao + ' - ' + formatedPrice(Number(e.plano.valor)), style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'dataExpiracao', header: 'Expiração', sortable: true, body: (e: any) => formatedDateTime(e.dataExpiracao), style: { width: '125px' } },
        { field: 'email', header: 'E-mail', sortable: true, style: { width: '125px' } },
        { field: 'whatsapp', header: 'WhatsApp', sortable: true, body: (e: any) => formatedTelefone(e.whatsapp), style: { width: '125px' } },
        { field: 'ativo', header: 'Situação', body: (e: any) => labelAtivoInativo(e.ativo), style: { width: '75px' } },
        { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '8rem', width: '8rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
            formatedDateTime(value.dataExpiracao).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            formatedTelefone(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            (value.plano.descricao + ' - ' + formatedPrice(Number(value.plano.valor))).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.nome).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.email).toLowerCase().includes(filter.toLowerCase());
    }

    function finishRenovacao() {
        dataTableRef.current.refresh();
        getSaldoCredito();
    }

    return (
        <div>
            <BsTelaPadrao
                ref={dataTableRef}
                model={MODEL_REVENDA_MASTER}
                module={'Masters (Créditos: ' + credito + ')'}
                sortcolumn={'dataExpiracao'}
                sortcolumnorder={1}
                columns={cols}
                onFilterRegisters={onFilter}
            />
            <RenovarMaster
                ref={renovaRef}
                onFinish={finishRenovacao}
            />
            <Notificar ref={notificaRef} />
        </div>
    );
}
