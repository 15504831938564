import styled from "styled-components"
import { Card, useTheme } from '@mui/material';
import { COLOR_THEME } from "../../styles/theme";

const Container = styled.div`
    position: relative;
    display: flex;
    margin-top: 5px;
    align-items: center;
    justify-content: center;
`

const WrapperTitle = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
`
const Icon = styled.div`
    display: flex;
    margin-left: 15px;
    
`
const Title = styled.div`
    display: flex;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
`
const SubTitle = styled(Title)`
    font-size: 11px;
    font-weight: 100;
`

export const BsCard = (props: any) => {
    const theme = useTheme();

    return (
        <Container>
            <Card
                style={{
                    ...props.style,
                    height: props.height,
                    width: '100%',
                    padding: props.padding,
                    border: '1px solid rgba(150, 150, 150, 0.35)',
                    paddingTop: '5px'
                }}
            >
                <WrapperTitle style={{ color: theme.palette.mode === 'dark' ? '#fff' : COLOR_THEME }}>
                    <Icon>{props.icon}</Icon>
                    <div>
                        <Title>{props.title} </Title>
                        <SubTitle>{props.subtitle}</SubTitle>
                    </div>
                </WrapperTitle>
                {props.children}
            </Card>
        </Container>
    )
}       
