import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { MODEL_DISPAROS } from '../../../models';
import { CANCELAR_DISPAROS, CANCELAR_TODOS_PENDENTES } from '../../../routes_api';
import { formatedDateTimeSeconds, formatedTelefone, intToOrigemDisparo, intToSituacaoDisparo, labelOrigemDisparo, labelSituacaoDisparo } from '../../../utils';
import { BsConfirmation } from '../../../components/BsConfirmation';
import { useRef } from 'react';
import { useAuth } from '../../../contexts/AuthProvider/useAuth';
import { toast } from 'react-toastify';

export const Disparo = () => {
  const auth = useAuth();

  const confirmationRef = useRef<any>(null);
  const telaPadraoRef = useRef<any>(null);

  const cols: Array<BsColumns> = [
    { field: 'dataCadastro', header: 'Data Cadastro', sortable: true, body: (e) => formatedDateTimeSeconds(e.dataCadastro), style: { width: '150px' } },
    { field: 'dataEnvio', header: 'Data Envio', sortable: true, body: (e) => formatedDateTimeSeconds(e.dataEnvio), style: { width: '150px' } },
    { field: 'whatsapp', header: 'Destino', sortable: true, body: (e) => formatedTelefone(e.whatsapp), style: { width: '150px' } },
    { field: 'texto', header: 'Mensagem', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'cliente.nome', header: 'Cliente', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'origem', header: 'Origem', body: (e) => labelOrigemDisparo(e.origem), style: { width: '150px' } },
    { field: 'situacao', header: 'Situação Disparo', body: (e) => labelSituacaoDisparo(e.situacao), style: { width: '150px' } },
    { field: 'edit', exportable: false, style: { width: '5rem' } }
  ];

  const onFilter = (value: any, filter: string) => {
    return formatedDateTimeSeconds(value.dataCadastro).toLowerCase().includes(filter.toLowerCase()) ||
      formatedDateTimeSeconds(value.dataEnvio).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
      formatedTelefone(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
      intToSituacaoDisparo(value.situacao).toLowerCase().includes(filter.toLowerCase()) ||
      intToOrigemDisparo(value.origem).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.cliente.nome).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.texto).toLowerCase().includes(filter.toLowerCase());
  }

  const functionsGeral: Array<any> = [
    {
      icon: 'pi pi-times',
      label: 'Cancelar Pendentes',
      onClick: () => confirmationRef.current.openModal()
    }
  ]

  async function cancelarPendentes() {
    await auth.requestPost(CANCELAR_TODOS_PENDENTES).then((response: any) => {
      toast.success('Registros cancelados com sucesso.');
      telaPadraoRef.current.refresh();
    }).catch((error: any) => {
      toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
    });
  }

  return (
    <div>
      <BsTelaPadrao
        ref={telaPadraoRef}
        model={MODEL_DISPAROS}
        cancel={CANCELAR_DISPAROS}
        disabledEdit
        sortcolumn={'dataCadastro'}
        sortcolumnorder={-1}
        columns={cols}
        onFilterRegisters={onFilter}
        adicionalFunctions={functionsGeral}
      />

      <BsConfirmation ref={confirmationRef} confirmClick={() => cancelarPendentes()} message='Deseja mesmo cancelar todos os disparos pendentes?' confirmAlert={true} />
    </div>
  );
}
