import React from 'react'
import classNames from 'classnames';
import { Button, ButtonProps } from 'primereact/button';

export const BsButtonDataTable: React.FC<ButtonProps> = ({ className, ...props }) => {
    return (
        <Button
            className={classNames(className, 'p-button-rounded')}
            style={{ marginRight: '5px', marginLeft: '5px', maxWidth: '2rem', maxHeight: '2rem'}}
            {...props}
        />
    )
}