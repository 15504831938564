import { Toolbar, Typography } from "@mui/material";
import Logo_Branca from '../../icones/Logo_Branco.png'

export const BsToolBarLogo = (props: any) => {
    const appVersion = process.env.REACT_APP_VERSION;
    return (
        <Toolbar variant='dense' >
            <div style={{ width: 150 }}>
                <img
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: `${window.innerWidth > 800 ? '-0.5rem' : ''}` }}
                    src={Logo_Branca}
                    alt=''
                    width='120rem'
                />
            </div>
            <Typography variant="body2" style={{ fontSize: '10px', position: 'absolute', left: 52, bottom: 0, marginRight: '0.5rem' }}>
                {'Versão: ' + appVersion}
            </Typography>
            {props.children}
        </Toolbar>
    )
}