import { ToastContainer } from "react-toastify"
import LogoBranca128 from "../../images/LogoBranca128.png"
import './styles.css';

export const BsNotification = () => {
    return (
        <ToastContainer
            position="top-center"
            autoClose={5000}
            limit={3}
            closeOnClick
            theme="colored"
            style={{ fontSize: '14px'}}
            icon={<img src={LogoBranca128} width={'28px'} alt='' style={{ marginRight: '10px' }} />}
        />
    )
}