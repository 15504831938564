import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-width: 100%;
  background-color: #f6f9ff;
`

export const InternalContainer = styled.div`
  display: flex;
  padding: 2em;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  max-width: ${String(Math.min(350, window.innerWidth - 30)) + 'px'};
  max-height: 550px;
  box-shadow: rgba(13, 110, 253, .30) 0px -2px 30px 0px;
  flex-direction: column;
`
export const ImgLogo = styled.img`
  width: 200px;
  margin-bottom: 10px;
`