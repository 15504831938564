import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Form, InputGroup, Col } from "react-bootstrap";
import { NumericFormat } from 'react-number-format';
import { FormFeedback, FormLabel, GroupText } from "../Controls";

interface MonetaryInputProps {
    ref?: any;
    id: string;
    label?: string;
    feedback?: string;
    required?: boolean;
    md?: string;
    value?: any;
    disabled?: any;
    simbolstart?: any;
    size?: any;
    onAfterChange?: any;
    onBeforeValidate?: any;
    isGreaterThanZero?: any;
    validated?: any;
}

export const MonetaryInput: React.FC<MonetaryInputProps> = forwardRef((props: MonetaryInputProps, ref: any) => {
    const inputRef = useRef<any>();

    const [value, setValue] = useState<any>(props.value ?? 0);
    const [error, setError] = useState<boolean>(false);

    function MonetaryToNumber(_value: any) {
        return Number(Number(String(_value).replace('R$ ', '').replace(',', '.')).toFixed(2));
    }

    function getValue() {
        return MonetaryToNumber(value);
    }

    function getId() {
        return props.id;
    }

    function setErrorMessage(msg: string) {
        setError(true);

    }

    useImperativeHandle(ref, () => ({
        getValue,
        setValue,
        setErrorMessage,
        validate,
        getId
    }));

    function validate(_value?: any) {
        _value = _value ? _value : value;

        if (props.onBeforeValidate) {
            let _msg = props.onBeforeValidate(MonetaryToNumber(_value));

            if (_msg) {
                inputRef.current.setCustomValidity('Invalid field');
                return false
            } else
                inputRef.current.setCustomValidity('');
        }

        return true;
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (error)
            setError(false);

        if (props.onAfterChange)
            props.onAfterChange(String(e.target.value).replace('R$ ', '').replace(',', '.'));

        setValue(e.target.value);
        validate(e.target.value);
    }

    return (
        <Form.Group as={Col} md={props?.md || "12"} controlId={props.id}>
            {props.label && <FormLabel>{(props.required ? '*' : '') + props.label}</FormLabel>}
            <InputGroup size={props.size ? props.size : "sm"} hasValidation>
                {props.simbolstart && <GroupText>{props.simbolstart}</GroupText>}
                <NumericFormat
                    value={value}
                    customInput={Form.Control}
                    getInputRef={inputRef}
                    decimalSeparator={','}
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                    spellCheck={false}
                    required={props.required}
                    isInvalid={error}
                    disabled={props.disabled}
                    style={{ textAlign: 'right', fontSize: '14px' }}
                    onChange={onChange}
                    className={error ? 'is-invalid' : ''}
                />
                <FormFeedback type="invalid">
                    {
                        (props.validated && props.onBeforeValidate) ? props.onBeforeValidate(MonetaryToNumber(value)) :
                            error && (props.feedback || (props.required ? 'Campo obrigatório.' : ''))
                    }
                </FormFeedback>
            </InputGroup>
        </Form.Group>
    );
});
