import React, { useImperativeHandle, useRef } from 'react';
import { CadastroPadrao } from '../../../../new_components/CadastroPadrao';
import { model_pagamento } from '../../../../models';

export const Pagamento = React.forwardRef((props: any, ref) => {
    const refmodal = useRef<any>(null);

    function openModal(_data: any) {
        refmodal.current.openModal(_data);
    }

    useImperativeHandle(ref, () => ({
        openModal: openModal
    }));

    return (
        <CadastroPadrao
            ref={refmodal}
            model={model_pagamento}
            success={props.success}
        />
    )

})