import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CONSULTA_VARIAVEIS } from "../../../../routes_api";
import { useAuth } from "../../../../contexts/AuthProvider/useAuth";

let TVariaveis = {
    descricao: ''
}

export const InstrucoesTemplateMensagem = () => {
    const auth = useAuth();
    const [variaveis, setVariaveis] = useState(TVariaveis);

    useEffect(() => {
        async function fetchData() {
            const response: any = await auth.requestGet(CONSULTA_VARIAVEIS);
            setVariaveis(response.data.dados[0]);
        }

        fetchData();
    }, [auth]);

    return (
        <div style={{ marginTop: '-15px', border: '1px solid gray', borderRadius: '5px', padding: '10px' }}>
            <Typography variant="button" display="block">
                {'INSTRUÇÕES:'}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {'Customize como desejar as mensagens de envio. Quando utilizado alguma das variáveis disponíveis a plataforma substituirá pelo valor referente a variável.'}
                <br />
            </Typography>
            <Typography variant="button" display="block">
                {'EXEMPLO:'}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {'Desejo colocar na mensagem o nome do meu cliente. Dessa forma preciso apenas adicionar {nome} onde eu desejar que apareça.'}
                <br />
                {'- Mensagem Padrão: Olá {nome}!'}
                <br />
                {'- Como ficará: Olá Bitsafira!'}
                <br />
            </Typography>
            <Typography variant="button" display="block">
                {'VARIÁVEIS DISPONÍVEIS:'}
            </Typography>
            <Typography variant="body2" gutterBottom>
                {variaveis.descricao}
            </Typography>
        </div >
    )
}