import { Grid, Typography } from "@mui/material"
import Logo from '../../../icones/Logo.png'
import { MobVersion } from "../../../utils"

export const PaginaIndisponivel = (props: any) => {
    return (
        <div style={{ transform: 'translate(0%, 100%)' }}>
            <Grid container>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <img
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginLeft: `${MobVersion() ? '-0.5rem' : ''}`
                        }}
                        src={Logo}
                        alt=''
                        width='300rem'
                    />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <Typography variant="h6" gutterBottom>{props?.description ? props?.description : 'Página Indisponível'}</Typography>
                </Grid>
            </Grid>
        </div >
    )
}