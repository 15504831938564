import { useEffect, useRef } from 'react'
import Box from '@mui/material/Box';
import { AppRoutes } from '../../routes';
import { Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { COLOR_THEME } from '../../styles/theme';
import { BsIconButtonAuxSideBar } from '../../components/BsIconButton';
import { MobVersion } from '../../utils';
import { BsDrawer } from '../../components/BsDrawer';
import { BsAppBar, BsMain } from '../../components/BsSideBarUtils';
import ReactGA from "react-ga4";
import { MenuPerfil } from './MenuPerfil';
import { LabelVencimento } from './LabelVencimento';
import { SideBar } from './SideBar';
import { ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { BsSwitchTheme } from '../../components/BsSwitchTheme';

export const Principal = () => {
    const location = useLocation();

    const labelRef = useRef<any>(null);
    const perfilRef = useRef<any>(null);

    const [darkMode, setDarkMode] = useState<any>(localStorage.getItem('theme_bitsafira') === 'dark');
    const [expanded, setExpanded] = useState(!MobVersion());

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", title: 'Painel BitSafira - ' + location.pathname });
    }, [location]);

    useEffect(() => {
        const theme = darkMode ? '../themes/md-dark-indigo/theme.css' : '../themes/md-light-indigo/theme.css';

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = theme;
        link.id = 'theme-stylesheet';

        document.head.appendChild(link);

        const htmlElement = document.documentElement;
        const themeType = darkMode ? 'dark' : 'light';

        htmlElement.setAttribute('data-theme', themeType);
        htmlElement.setAttribute('data-bs-theme', themeType);

        return () => {
            const existingLink = document.getElementById('theme-stylesheet');
            if (existingLink) {
                existingLink.remove();
            }
        };
    }, [darkMode]);

    useEffect(() => {
        labelRef.current.refreshMaster();
    }, [])

    const clickExpanded = () => {
        setExpanded(!expanded)
    }

    const clickItemMenu = (to: any) => {
        setExpanded(to === undefined ? true : !MobVersion());
    };

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: darkMode ? 'white' : 'black',
                        backgroundColor: darkMode ? 'black' : 'white',
                        '&:hover': {
                            backgroundColor: darkMode ? '#333' : '#ddd',
                        },
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: darkMode ? '#181818' : 'white'
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        backgroundColor: darkMode ? '#181818' : 'white',
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        backgroundColor: darkMode ? '#181818' : 'white',
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        border: '1px solid #000',
                        margin: 0
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <Box style={{ display: 'flex' }}>
                <BsAppBar position="fixed" open={expanded}>
                    <Toolbar variant='dense' style={{ backgroundColor: COLOR_THEME }} >
                        <BsIconButtonAuxSideBar onClick={clickExpanded} />
                        <BsSwitchTheme
                            defaultChecked={darkMode}
                            sx={{ position: `${MobVersion() ? 'fixed' : 'relative'}`, left: `${MobVersion() ? '3rem' : '0'}` }}
                            onChange={() => { localStorage.setItem('theme_bitsafira', darkMode ? 'light' : 'dark'); setDarkMode(!darkMode) }}
                        />
                        <MenuPerfil ref={perfilRef} />
                    </Toolbar>
                </BsAppBar>

                <BsDrawer open={expanded} variant={MobVersion() ? "temporary" : "persistent"} >
                    <SideBar clickExpanded={clickExpanded} clickItemMenu={clickItemMenu} />
                </BsDrawer>

                <BsMain open={expanded}>
                    <div style={{ padding: '8px', marginTop: '48px', backgroundColor: darkMode ? '#181818' : '#f0f0f0', borderTopLeftRadius: '6px' }}>
                        <LabelVencimento ref={labelRef} />
                        <AppRoutes />
                    </div>
                </BsMain>
            </Box >
        </ThemeProvider >
    );
}