import { BsColumns, BsTelaPadrao } from "../../../components/BsTelaPadrao";
import { MODEL_ASSISTENTE } from "../../../models";
import { EXCLUIR_ASSISTENTE } from "../../../routes_api";
import { intToAtivoInativo, intToTipoAssistente, labelAtivoInativo } from "../../../utils";

export const Assistente = (props: any) => {
    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'descricao', header: 'Descrição', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'instancia.descricao', header: 'Instância', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'publicIdTypeBot', header: 'ID TypeBot', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'ativo', header: 'Situação', body: (e) => labelAtivoInativo(e.ativo), style: { width: '75px' } },
        { field: 'edit', exportable: false, style: { minWidth: '8rem', width: '10rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
            intToTipoAssistente(value.tipo).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.descricao).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.instancia.descricao).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.quantidadeResposta).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.publicIdTypeBot).toLowerCase().includes(filter.toLowerCase());
    }

    return (
        <BsTelaPadrao
            prefixApiDelete={EXCLUIR_ASSISTENTE}
            sortcolumn={'contador'}
            sortcolumnorder={1}
            columns={cols}
            model={MODEL_ASSISTENTE}
            onFilterRegisters={onFilter}
        />
    )
}