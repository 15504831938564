import React, { useImperativeHandle, useRef, useState } from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { COLOR_THEME } from "../../styles/theme";

export const BsLoadingButton: React.FC<LoadingButtonProps> = React.forwardRef((props, ref: any) => {
    const internalRef = useRef<any>(null);
    const [loading, setLoading] = useState(false);

    function click() {
        internalRef.current.click();
    }

    useImperativeHandle(ref, () => ({
        click,
        setLoading
    }));

    return (
        <LoadingButton
            ref={internalRef}
            size={props.size ? props.size : 'small'}
            variant="contained"
            loading={props.loading || loading}
            sx={{ ...props.sx, color: '#fff' }}
            style={{ backgroundColor: COLOR_THEME, ...props.style, }}
            {...props} />
    )
});