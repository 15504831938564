import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { MODEL_ATEMDIMENTOIA } from '../../../models';
import { formatedDateTime, formatedTelefone, intToSituacaoAtendimento, labelSituacaoAtendimento } from '../../../utils';

export const AtendimentoIA = () => {
  const cols: Array<BsColumns> = [
    { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
    { field: 'assistente.descricao', header: 'Assistente', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'whatsapp', header: 'Whatsapp', sortable: true, body: (e: any) => formatedTelefone(e.whatsapp), style: { width: '150px' } },
    { field: 'dataInicio', header: 'Data Início', sortable: true, body: (e: any) => formatedDateTime(e.dataInicio), style: { width: '150px' } },
    { field: 'atualizado', header: 'Atualizado', sortable: true, body: (e: any) => formatedDateTime(e.atualizado), style: { width: '150px' } },
    { field: 'pausadoAte', header: 'Pausado Até', sortable: true, body: (e: any) => formatedDateTime(e.pausadoAte), style: { width: '150px' } },
    { field: 'quantidade', header: 'Quantidade Respostas', sortable: true, },
    { field: 'situacao', header: 'Situacao', sortable: true, body: (e: any) => labelSituacaoAtendimento(e.situacao), style: { width: '100px' } },
  ];

  const onFilter = (value: any, filter: string) => {
    const filterLower = filter.toLowerCase();
    return [
      value.contador,
      value.assistente.descricao,
      formatedTelefone(value.whatsapp),
      formatedDateTime(value.dataInicio),
      formatedDateTime(value.atualizado),
      formatedDateTime(value.pausadoAte),
      value.quantidade,
      intToSituacaoAtendimento(value.situacao)
    ].some((field) => String(field).toLowerCase().includes(filterLower));
  }

  return (
    <div>
      <BsTelaPadrao
        model={MODEL_ATEMDIMENTOIA}
        sortcolumn={'contador'}
        sortcolumnorder={-1}
        columns={cols}
        onFilterRegisters={onFilter}
      />
    </div>
  );
}
