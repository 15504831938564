import { Card, Typography } from "@mui/material";
import { COLOR_THEME, COLOR_THEME_FONT } from "../../styles/theme";

export const BsTitle = (props: any) => {
    return (
        <Card style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            backgroundColor: props.backgroundColor ? props.backgroundColor : COLOR_THEME,
            color: COLOR_THEME_FONT,
            height: 'auto',
            marginBottom: props.marginBottom ? props.marginBottom : '0px',
            borderTopRightRadius: 3,
            borderTopLeftRadius: 3,
            borderEndEndRadius: props.borderBottom ? props.borderBottom : 0,
            borderEndStartRadius: props.borderBottom ? props.borderBottom : 0
        }}>
            <Typography variant='body2' letterSpacing={0} style={{ padding: 1 }}>{props.title}</Typography>
        </Card >
    )
}