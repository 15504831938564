import { Grid } from "@mui/material";
import React, { useContext, useImperativeHandle, useRef, useState } from "react";
import { AuthContext } from "../../../../contexts/AuthProvider";
import { ADICIONAR_CREDITO } from "../../../../routes_api";
import { BsDialog } from "../../../../components/BsDialog";
import { Inputs } from "../../../../components/Inputs";
import { toast } from "react-toastify";

interface Props {
    onFinish: any;
}

export const AdicionarCredito = React.forwardRef((props: Props, ref) => {
    const auth = useContext(AuthContext);

    const [register, setRegister] = useState<any>(false);

    const dialogRef = useRef<any>();
    const itemEls = useRef<{ [key: string]: any }>({});

    function setLoading(value: boolean) {
        dialogRef.current.setLoading(value);

        if (itemEls)
            Object.values(itemEls.current).forEach((elementRef: any) => { elementRef.setLoading(value) });
    }

    function openModal(data: any) {
        dialogRef.current.openModal();
        setRegister(data);
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    const adicionarCredito = () => {
        async function adicionaCredito() {
            setLoading(true);

            let request: any = {
                contador: register.contador,
                quantidadeCredito: Number(itemEls.current['novoCredito'].getValue())
            };

            try {
                const response: any = await auth.requestPost(ADICIONAR_CREDITO, request);

                toast.success(response.data.mensagem);

                dialogRef.current.closeModal();

                props.onFinish(true);
            } catch (error: any) {
                toast.success(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
                setLoading(false);
            }

            dialogRef.current.setLoading(false);
        }

        if (itemEls.current['novoCredito'].validate())
            adicionaCredito();
    }

    function changeCredito(_newValue: any) {
        const _saldoAtual = itemEls.current['credito'].getValue();
        itemEls.current['novoSaldo'].setCustomValue(Number(_saldoAtual) + Number(_newValue))
    }

    const inputs = [
        { id: 'nome', label: 'Master', type: 'text', disabled: true, xs: 12, multiline: true, maxRows: 4 },
        { id: 'credito', label: 'Saldo Atual', xs: 12, type: 'number', disabled: true },
        { id: 'novoCredito', label: 'Crédito', xs: 12, type: 'number', required: true, isGreaterThanZero: true, default: 1, onAfterChange: changeCredito },
        { id: 'novoSaldo', label: 'Novo Saldo', disabled: true, xs: 12, type: 'number' },
    ]

    return (
        <BsDialog
            ref={dialogRef}
            maxWidth={'xs'}
            title={'Adicionar Crédito'}
            cancelClick={() => dialogRef.current.closeModal()}
            confirmClick={adicionarCredito}
            content={
                <Grid container spacing={2} sx={{ mt: 0.5 }} >
                    {
                        inputs.map((input: any, i: number) => (
                            <Grid key={i} item xs={12}>
                                <Inputs
                                    ref={(e: any) => itemEls.current[input.id] = e}
                                    inp={input}
                                    submitted={true}
                                    register={register}
                                    onAfterChange={(_newValue: any) => { input.inp.onAfterChange(_newValue) }}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            }
        />
    )
});