import { forwardRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { CheckBox } from "../CheckBox";
import { Input } from "../Input";
import { MonetaryInput } from "../MonetaryInput";

export const FormLabel = styled(Form.Label)`
    margin-left: .375rem;
    margin-bottom: .275rem;  
    font-size: .785rem;
    font-weight: bold;
`

export const FormFeedback = styled(Form.Control.Feedback)`
    margin: 0;
    font-size: .785rem;
    font-weight: 500;
`

export const GroupText = styled(InputGroup.Text)`
    width: 25px;
    display: flex;
    justify-content: center;
`
interface ControlsProps {
    id: any;
    name: any;
    type: any;
    label?: string;
    placeholder?: any;
    defaultValue?: any;
    feedback?: string;
    textfixed?: string;
    required?: any;
    md?: string;
    size?: any;
    onAfterChange?: any;
    search?: any;
    disabled?: any;
    options?: any;
    model?: any;
    validated?: any;
    maxLength?: any;
    textarea?: any;
    rows?: any;
    params_required?: any;
    defaultEmpId?: any;
    isMulti?: any;
    onBeforeValidate?: any;
    isGreaterThanZero?: any;
}

export const Controls = forwardRef((props: ControlsProps, ref: any) => {
    const { options, ...input } = props;

    return (
        <>
            {
                props.type === 'monetary' ?
                    <MonetaryInput
                        ref={ref}
                        id={props.id}
                        label={props.label}
                        feedback={props.feedback}
                        required={props.required}
                        md={props.md}
                        disabled={props.disabled}
                        size={props.size}
                        onAfterChange={props.onAfterChange}
                        value={props.defaultValue}
                        onBeforeValidate={props.onBeforeValidate}
                        isGreaterThanZero={props.isGreaterThanZero}
                        validated={props.validated}
                    /> :
                    props.type === 'check' ?
                        <CheckBox
                            ref={ref}
                            id={props.id}
                            label={props.label}
                            defaultValue={props.defaultValue}
                            md={props.md}
                            size={props.size}
                        /> :
                        <Input ref={ref} {...input} />
            }
        </>
    )
})