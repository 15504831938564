import { Drawer, DrawerProps } from "@mui/material";
import React from "react";
import { COLOR_THEME, COLOR_THEME_FONT } from "../../styles/theme";
import { lighten } from "polished";

export const DRAWER_WIDTH = 225;

export const BsDrawer: React.FC<DrawerProps> = (props) => {
    return (
        <Drawer
            anchor={'left'}
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: DRAWER_WIDTH,
                    background: COLOR_THEME,
                    boxSizing: 'border-box',
                    borderRight: '0px',
                    '&::-webkit-scrollbar': {
                        width: '8px'
                    },
                    '&::-webkit-scrollbar-track': {
                        background: COLOR_THEME
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: lighten(.1, COLOR_THEME),
                        borderRadius: '3px'
                    },
                },
            }}
            PaperProps={{
                style: { backgroundColor: COLOR_THEME, color: COLOR_THEME_FONT }
            }}
            {...props}
        />
    )
}