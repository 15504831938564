import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { MODEL_TEMPLATE_MENSAGEM } from '../../../models';
import { DELETAR_IMAGEM_TEMPLATE, EXCLUIR_TEMPLATEMENSAGEM } from '../../../routes_api';
import { InstrucoesTemplateMensagem } from './Instrucoes';
import { intToSimNao, labelSimNao } from '../../../utils';
import { useRef, useState } from 'react';
import { AnexarImagem } from './AnexarImagem';
import { BsConfirmation } from '../../../components/BsConfirmation';
import { useAuth } from '../../../contexts/AuthProvider/useAuth';
import { toast } from 'react-toastify';
import { BsViewImg } from '../../../components/BsImgView';
import { BsCircularLoadingModal } from '../../../components/BsCircularLoadingModal';
import { AiOutlinePaperClip } from "react-icons/ai";
import { IoIosImages } from "react-icons/io";
import { PiEraserFill } from "react-icons/pi";

export const TemplateMensagem = () => {
  const auth = useAuth();

  const [loading, setLoading] = useState(false);

  const telaPadraoRef = useRef<any>();
  const anexarImgRef = useRef<any>();
  const confirmationRef = useRef<any>();
  const imgViewRef = useRef<any>();

  function anexarImgClick(row: any) {
    anexarImgRef.current.openModal(row);
  }

  function deleteImgClick(row: any) {
    if (row.idArquivo === null)
      toast.error('Template não possui imagem anexada.')
    else
      confirmationRef.current.openModal(row.contador);
  }

  async function deleteImg(contador: any) {
    await auth.requestDelete(DELETAR_IMAGEM_TEMPLATE, { contador: contador })
      .then(() => {
        toast.success('Imagem excluída com sucesso.');
        telaPadraoRef.current.refresh();
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
      })
  }

  async function viewImgClick(row: any) {
    if (row.idArquivo === null)
      toast.error('Template não possui imagem anexada.')
    else {
      setLoading(true);

      await auth.requestGet('/arquivo/base64/' + row.idArquivo)
        .then((response: any) => {
          imgViewRef.current.openModal(response.data.dados.base64)
        })
        .catch((error: any) => {
          toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }

  const functionsGrid: Array<any> = [
    { icon: <IoIosImages />, color: '#0D9488', onClick: (row: any) => viewImgClick(row), tooltip: 'Visualizar Imagem' },
    { icon: <AiOutlinePaperClip />, color: '#9333EA', onClick: (row: any) => anexarImgClick(row), tooltip: 'Anexar/Alterar Imagem' },
    { icon: <PiEraserFill />, color: '#EF4444', onClick: (row: any) => deleteImgClick(row), tooltip: 'Excluir Imagem' }
  ]

  const cols: Array<BsColumns> = [
    { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
    { field: 'descricao', header: 'Descrição', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'tipo.descricao', header: 'Tipo', sortable: true },
    { field: 'diasIntervalo', header: 'Dia Intervalo', sortable: true },
    { field: 'texto', header: 'Mensagem', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'tipoServico.descricao', header: 'Tipo Serviço', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'idArquivo', header: 'Possui Arquivo', sortable: false, body: (e) => labelSimNao(e.idArquivo), style: { width: '70px' } },
    { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { width: 'auto' } }
  ];

  const onFilter = (value: any, filter: string) => {
    return String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.descricao).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.tipo.descricao).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.diasIntervalo).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.texto).toLowerCase().includes(filter.toLowerCase()) ||
      String(value.tipoServico.descricao).toLowerCase().includes(filter.toLowerCase()) ||
      intToSimNao(value.idArquivo).toLowerCase().includes(filter.toLowerCase());
  }

  return (
    <div>
      <BsTelaPadrao
        ref={telaPadraoRef}
        model={MODEL_TEMPLATE_MENSAGEM}
        prefixApiDelete={EXCLUIR_TEMPLATEMENSAGEM}
        sortcolumn={'contador'}
        sortcolumnorder={1}
        columns={cols}
        headerDialog={InstrucoesTemplateMensagem}
        sizeDialog={'sm'}
        onFilterRegisters={onFilter}
      />
      <BsConfirmation
        ref={confirmationRef}
        confirmClick={(contador: any) => deleteImg(contador)}
        message={'Deseja mesmo excluir a imagem do Template?'}
        confirmAlert={true}
      />
      <AnexarImagem ref={anexarImgRef} onAfterSave={() => telaPadraoRef.current.refresh()} />
      <BsCircularLoadingModal open={loading} title={'Carregando imagem...'} />
      <BsViewImg ref={imgViewRef} />
    </div>
  );
}
