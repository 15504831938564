import React from 'react'
import { Paper, PaperProps } from '@mui/material';

export interface Props extends PaperProps {
    color: string;
}

export const BsLabelDataTable: React.FC<Props> = (props) => {
    return (
        <Paper
            elevation={0}
            style={{
                textAlign: 'center',
                height: 'auto',
                width: 'auto',
                lineHeight: '20px',
                backgroundColor: `${props.color}`,
                color: '#fff',
                fontWeight: 'bold',
                paddingLeft: '5px',
                paddingRight: '5px'
            }}
            {...props}
        />
    )
}