import { useAuth } from '../../contexts/AuthProvider/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { FormControl, FormHelperText, Grid, Input, InputLabel, Typography } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from '../../icones/Logo.png'
import { BsLoadingButton } from '../../components/BsLoadingButton'
import { BsTextField } from '../../components/BsBaseField'
import { COLOR_THEME } from '../../styles/theme'
import { RelembrarAcesso } from './RelembrarAcesso'
import { TermosUso } from '../Outras/TermosUso'
import { RenovacaoPainel } from '../Comuns/Renovacao'
import { Container, InternalContainer, ImgLogo } from './styles'
import { BsConfirmation } from '../../components/BsConfirmation'
import { encrypt } from '../../utils'
import { toast } from 'react-toastify'

const LinkLabel = (props: any) => {
  return (
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', paddingTop: 3 }}>
      <Typography variant="body2" sx={{ fontSize: 13 }} >
        <Link style={{ textDecoration: 'none', color: COLOR_THEME }} to='' onClick={props.onClick}>{props.label}</Link>
      </Typography>
    </Grid>
  )
}

export const Login = () => {
  const auth = useAuth();

  const entrarRef = useRef<any>();
  const renovacaoRef = useRef<any>();
  const confirmationRef = useRef<any>();
  const relembrarAcessoRef = useRef<any>();
  const termosRef = useRef<any>();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dadosRenovacao, setDadosRenovacao] = useState<any>();

  async function onFinish() {
    setSubmitted(true);

    if ((username !== '') && (password !== '')) {
      setLoading(true);

      try {
        await auth.authenticate(username, encrypt(password))
        navigate('/')
      } catch (error: any) {
        if (error.response.data.status === 406) {

          setDadosRenovacao({
            master: error.response.data.dados.contador,
            usuario: error.response.data.dados.usuarioContador,
            plano: error.response.data.dados.valorPlano
          })

          confirmationRef.current.openModal();

        } else if (error.code === 'ERR_NETWORK')
          toast.error('Erro de Conexão.')
        else
          toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
      }

      setLoading(false);
    }
  }

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if ((event.key === 'Enter') && (!relembrarAcessoRef.current.opened())) {
        entrarRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [entrarRef]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const navigate = useNavigate()

  return (
    <Container>
      <InternalContainer>
        <ImgLogo src={Logo} alt='' />

        <Typography variant="h6" >{'Faça login na sua conta'}</Typography>
        <Typography variant="body2" >{'Entre com seu usuário e senha'}</Typography>

        <Grid container spacing={1} style={{ paddingTop: 15 }}>
          <Grid item xs={12} >
            <BsTextField maxLength={15} type={'text'} label={'Usuário'} value={username} variant={'standard'} submitted={submitted} required validate
              onChange={
                (e: any) => setUserName(e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} >
            <FormControl fullWidth variant="standard" error={submitted && !password}>
              <InputLabel htmlFor="password" required style={{ fontSize: 13 }}>Senha</InputLabel >
              <Input id='password' type={showPassword ? 'text' : 'password'} size='small' value={password}
                onChange={
                  (e: any) => setPassword(e.target.value)
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} size='small' >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                sx={{ fontSize: 14 }}
              />
              <FormHelperText>{submitted && !password && 'Campo obrigatório'}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: 5 }}>
          <Grid item xs={12} >
            <BsLoadingButton ref={entrarRef} loading={loading} type="submit" fullWidth onClick={onFinish}>{'Entrar'}</BsLoadingButton>
          </Grid>
          <Grid item xs={12} >
            <LinkLabel label='Esqueceu a senha?' onClick={() => relembrarAcessoRef.current.openModal()} />
            <LinkLabel label='Termos de uso' onClick={() => termosRef.current.openModal()} />
          </Grid>
        </Grid>

        <RelembrarAcesso ref={relembrarAcessoRef} />
        <TermosUso ref={termosRef} />
        <RenovacaoPainel ref={renovacaoRef} dados={dadosRenovacao} />
        <BsConfirmation ref={confirmationRef} message={'Usuário Bloqueado! Deseja realizar a renovação agora?'} confirmClick={() => renovacaoRef.current.openModal()} />
      </InternalContainer>
    </Container >
  )
}