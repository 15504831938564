import React, { useEffect } from 'react';
import { COLOR_THEME } from '../../styles/theme';
import { MobVersion } from '../../utils';

const ChatWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://chat-api.spartez-software.com/chat-widget.js';
    script.defer = true;
    script.onload = () => {
      createChatWidget();
    };
    document.body.appendChild(script);

    // Adicionar estilos para o chat-widget
    const style = document.createElement('style');
    style.innerHTML = `
      chat-widget::part(wrapper) { 
        right: ${MobVersion() ? `0px` : '15px'};
        bottom: 0px;
      }
      chat-widget::part(icon-button) {
        max-height: 50px;
        max-width: 50px;
        border-radius: 20px;
      }
      chat-widget::part(online-icon) {
        background-image: url(https://bitsafira.com.br/images/foneChat.png);
        color: #fff;
      }`;
    document.head.appendChild(style);

    return () => {
      document.body.removeChild(script);
      document.head.removeChild(style);
    };
  }, []);

  const createChatWidget = () => {
    const chatWidget = document.createElement('chat-widget');
    chatWidget.setAttribute('jira-id', '9ef32068-93d6-3eed-b96b-d4824fa648bc');
    chatWidget.setAttribute('primary-color', COLOR_THEME);
    chatWidget.setAttribute('service-desk-id', '1');
    document.body.appendChild(chatWidget);
  };

  return <div id="chat-widget-container" />;
};

export default ChatWidget;
