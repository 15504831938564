import { ListItemIcon, ListItemIconProps } from "@mui/material"
import React from "react"

interface BsListItemIconProps extends ListItemIconProps {
    icon?: any;
}

export const BsListItemIcon = (props: BsListItemIconProps) => {
    return (
        <ListItemIcon
            sx={{
                color: 'inherit',
                marginRight: '-1.5rem'
            }}
            {...props}
        >
            {React.cloneElement(props.icon, { style: { fontSize: '1.3rem', marginBottom: 3 } })}
        </ListItemIcon>
    )
}