import { createContext, useState } from 'react';
import { IParamsProvider, IParamsContext, IParams } from './types';
import { useAuth } from '../AuthProvider/useAuth';
import { CONSULTA_PARAMETROS } from '../../routes_api';

export const ParamsContext = createContext<IParamsContext>({} as IParamsContext);

export const ParamsProvider = ({ children }: IParamsProvider) => {
    const auth = useAuth();
    const [params, setParams] = useState<IParams | null>();

    async function loadParams() {
        const response: any = await auth.requestGet(CONSULTA_PARAMETROS);

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            setParams(
                {
                    horarioDisparo: response.data.dados[0].horarioDisparo,
                    dataAtualRenovacao: response.data.dados[0].dataAtualRenovacao,
                    desconsiderarCobranca: response.data.dados[0].desconsiderarCobranca 
                }
            );
        }
    }

    return (
        <ParamsContext.Provider value={{ ...params, loadParams }}>
            {children}
        </ParamsContext.Provider>
    )
}