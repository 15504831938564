import { Grid } from "@mui/material"

import React, { useImperativeHandle, useRef, useState } from "react";
import { BsTextField } from "../../../components/BsBaseField"
import { BsLoadingButton } from "../../../components/BsLoadingButton";
import { toast } from 'react-toastify';
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { RELEMBRAR_ACESSO } from "../../../routes_api";
import { BsDialog } from "../../../components/BsDialog";

export const RelembrarAcesso = React.forwardRef((props: any, ref) => {
    const auth = useAuth();
    

    const dialogRef = useRef<any>();

    const [whatsApp, setWhatsApp] = useState('');
    const [submitted, setSubmitted] = useState(false);

    function setLoading(value: boolean) {
        dialogRef.current.setLoading(value)
    };

    function openModal() {
        dialogRef.current.openModal()
    };

    function opened() {
        return dialogRef.current.opened()
    };

    const closeModal = () => {
        setLoading(false);
        setWhatsApp('');
        setSubmitted(false);
        dialogRef.current.closeModal();
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal,
        opened
    }));

    function recuperarSenha() {
        async function recuperar() {
            setLoading(true);

            try {
                const response: any = await auth.requestPost(RELEMBRAR_ACESSO, { whatsapp: whatsApp });
                closeModal();
                toast.success(response.data.mensagem);
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
            }

            setLoading(false);
        }

        setSubmitted(true);

        if (whatsApp)
            recuperar();
    }

    return (
        <BsDialog
            ref={dialogRef}
            maxWidth={'xs'}
            title={'Esqueceu a senha?'}
            content={
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}> <BsTextField label='WhatsApp' type='text' value={whatsApp} onChange={(e: any) => setWhatsApp(e.target.value)} submitted={submitted} maxLength={20} required /> </Grid>
                    <Grid item xs={12}> <BsLoadingButton fullWidth variant='contained' onClick={() => { recuperarSenha() }}>Recuperar</BsLoadingButton> </Grid>
                    <Grid item xs={12}> <BsLoadingButton fullWidth variant='contained' onClick={() => closeModal()}>Voltar</BsLoadingButton> </Grid>
                </Grid>
            }
            invisibleActions={true}
        />

    )
})