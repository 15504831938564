import { useRef, useState } from "react";
import { BsColumns, BsTelaPadrao } from "../../../components/BsTelaPadrao";
import { EXCLUIR_CLIENTESERVICO, NOTIFICAR_CLIENTE } from "../../../routes_api";
import { formatedDateTime, formatedPrice, formatedTelefone, getDataExpiracao, labelAtivoInativo, labelExpiracao } from "../../../utils";
import { toast } from 'react-toastify';
import { Notificar } from "../../Comuns/Notificar";
import { MODEL_CLIENTE, MODEL_GESTAO_SERVICO, MODEL_SERVICO, MODEL_TIPO_SERVICO } from "../../../models";
import { useTheme } from "@mui/material";
import { RenovarCliente } from "../../Comuns/Renovar";
import { MdAutorenew } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import { FilterButton } from "../../../new_components/FilterButton";

export const GestaoServico = () => {
    const theme = useTheme();
    const dataTableRef = useRef<any>(null);
    const renovaRef = useRef<any>(null);
    const notificaRef = useRef<any>(null);

    const [onlyExpired, setOnlyExpired] = useState(false);
    const [notified, setNotified] = useState<any[]>([]);

    async function renovarClick(row: any) {
        renovaRef.current.openModal(row.contador,
            {
                nome: row.cliente.nome,
                codigo: row.cliente.contador,
                bonus: row.cliente.bonus,
                expiracao: row.dataExpiracao
            },
            {
                descricao: row.servico.descricao,
                valor: row.valor
            }
        );
    }

    const notificarClick = (rowData: any) => {
        if (rowData.cliente.notifica === 1)
            notificaRef.current.openModal(rowData.contador, NOTIFICAR_CLIENTE, rowData.cliente.nome, 'Cliente');
        else
            toast.warning('Recurso disponível apenas para clientes com "Notifica" igual a "Sim".');
    }

    const functionsGrid: Array<any> = [
        { icon: <MdAutorenew />, color: '#2563EB', onClick: renovarClick },
        { icon: <FaWhatsapp />, color: '#10C245', onClick: notificarClick }
    ]

    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'cliente.nome', header: 'Cliente', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'cliente.whatsapp', header: 'WhatsApp', sortable: true, body: (e) => formatedTelefone(e.cliente.whatsapp), style: { width: '125px' } },
        { field: 'servico.descricao', header: 'Serviço', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'valor', header: 'Valor', sortable: true, body: (e: any) => formatedPrice(e.valor), style: { width: '125px' } },
        { field: 'dataExpiracao', header: 'Data Expiração', sortable: true, body: (e: any) => formatedDateTime(e.dataExpiracao), style: { width: '125px' } },
        { field: 'ativo', header: 'Situação', body: (e: any) => labelAtivoInativo(e.ativo), style: { width: '75px' } },
        { field: 'expira', exportable: false, body: (e) => labelExpiracao(e.dataExpiracao), style: { width: '175px' } },
        { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '6rem', width: '10rem' } }
    ];

    const colsExpanded: Array<BsColumns> = [
        { field: 'cliente.dataCadastro', header: 'Cadastro Cliente', body: (e: any) => formatedDateTime(e.cliente.dataCadastro), style: { width: '125px' } },
        { field: 'cliente.bonus', header: 'Saldo Bônus', body: (e: any) => formatedPrice(e.cliente.bonus) },
        { field: 'servico.tipoServico.descricao', header: 'Tipo Serviço', style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'cliente.notifica', header: 'Notifica', body: (e) => e.cliente.notifica === 0 ? 'Não' : 'Sim', style: { width: '75px' } },
        { field: 'login', header: 'Login', style: { width: '125px' } },
        { field: 'senha', header: 'Senha', style: { width: '125px' } },
        { field: 'observacao', header: 'Observação', style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } }
    ]

    const onFilter = (value: any, filter: string) => {
        const notificaConverted = value.cliente.notifica === 0 ? 'Não' : 'Sim';
        const servicoConverted = value.servico.descricao + ' - ' + formatedPrice(Number(value.servico.valor));

        return String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.cliente.nome).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.servico.descricao).toLowerCase().includes(filter.toLowerCase()) ||
            notificaConverted.toLowerCase().includes(filter.toLowerCase()) ||
            servicoConverted.toLowerCase().includes(filter.toLowerCase()) ||
            String(value.servico.tipoServico.descricao).toLowerCase().includes(filter.toLowerCase()) ||
            formatedDateTime(value.cliente.dataCadastro).toLowerCase().includes(filter.toLowerCase()) ||
            formatedDateTime(value.dataExpiracao).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.cliente.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            formatedTelefone(value.cliente.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            formatedPrice(value.valor).toLowerCase().includes(filter.toLowerCase());
    }

    const filterInputs: Array<any> = [
        { id: 'cliente', label: 'Cliente', type: 'select', multiple: true, model: MODEL_CLIENTE, showInativos: true },
        { id: 'servico', label: 'Serviço', type: 'select', multiple: true, model: MODEL_SERVICO },
        { id: 'servico.tipoServico', label: 'Tipo Serviço', type: 'select', multiple: true, model: MODEL_TIPO_SERVICO }
    ]

    const rowClass = (data: any) => {
        let result: any;
        theme.palette.mode === 'dark' ?
            result = {
                'bg-red-800': getDataExpiracao(data.dataExpiracao) === 'Expirado',
                'bg-orange-800': getDataExpiracao(data.dataExpiracao) === 'Expira Hoje',
                'bg-yellow-800': getDataExpiracao(data.dataExpiracao) === 'Expira Amanhã',
                'border-left-3': notified.includes(data.contador),
                'border-teal-500': notified.includes(data.contador)
            } :
            result = {
                'bg-red-100': getDataExpiracao(data.dataExpiracao) === 'Expirado',
                'bg-orange-100': getDataExpiracao(data.dataExpiracao) === 'Expira Hoje',
                'bg-yellow-100': getDataExpiracao(data.dataExpiracao) === 'Expira Amanhã',
                'border-left-3': notified.includes(data.contador),
                'border-teal-500': notified.includes(data.contador)
            }

        return result;
    };

    function addNotified(contador: any) {
        if (!notified.includes(contador)) {
            setNotified((prevNotified) => {
                const newNotified = [...prevNotified, contador];
                dataTableRef.current.renderize();
                return newNotified;
            });
        }
    }

    const filterOptions = () => (
        <div style={{ padding: '10px 0px 5px' }}>
            <FilterButton active={onlyExpired} label="Somente Expirados"
                onClick={() => {
                    setOnlyExpired(!onlyExpired);
                    dataTableRef.current.setRefreshFilter(true);
                }} color={'red'} />
        </div>
    );

    const applyFilter = (value: any) => {
        return (!onlyExpired) || (new Date() > new Date(value.dataExpiracao) );
    }

    return (
        <>
            <BsTelaPadrao
                ref={dataTableRef}
                prefixApiDelete={EXCLUIR_CLIENTESERVICO}
                sortcolumn={'dataExpiracao'}
                sortcolumnorder={1}
                columns={cols}
                columnsExpanded={colsExpanded}
                onFilterRegisters={onFilter}
                rowClass={rowClass}
                filterInputs={filterInputs}
                model={MODEL_GESTAO_SERVICO}
                disabledInativar={true}
                customHeader={filterOptions}
                customFilter={applyFilter}
            />
            <RenovarCliente
                ref={renovaRef}
                onFinish={(success: boolean) => { success && dataTableRef.current.refresh() }}
            />
            <Notificar
                ref={notificaRef}
                onAfterFinish={(contador: any) => addNotified(contador)}
            />
        </>

    );
}
